import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import * as Functions from '../../../utils/functions/Functions';
import CodeInput from '../../components/Inputs/InputCode/Input';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg';
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg';
import ConfusoIcon from '../../../assets/icons/confuso-icon.png';
import CarteiroIcon from '../../../assets/icons/carteiro-icon.png';
import ProibidoIcon from '../../../assets/icons/proibido-icon.png';
import CardeiraIcon from '../../../assets/icons/carteira-icone.png';
import TimidoIcon from '../../../assets/icons/timido-icone.png';

type PageNotFoundProps = {
	notFound?: boolean;
	noPermission?: boolean;
};

const PageNotFound: React.FC<PageNotFoundProps> = ({
	notFound = false,
	noPermission = false,
}) => {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	return (
		<Body.Container>
			<Body.Row>
				<Body.CompanyLogo
					style={{ height: '23px', marginLeft: '20px' }}
					src={DinamoLogo}
				/>
				<Body.CompanyLogo src={TectrolLogo} />
			</Body.Row>
			<Body.Title>Conecta</Body.Title>

			<Body.CardLogin>
				{notFound && (
					<>
						<Body.SubTitle>Não há nada aqui!</Body.SubTitle>
						<Body.Row>
							<img
								style={{
									height: '100px',
									marginRight: '-60px',
								}}
								src={ProibidoIcon}
							/>
							<img
								style={{
									height: '100px',
									marginRight: '-50px',
									zIndex: '10',
								}}
								src={CarteiroIcon}
							/>
							<img
								style={{ height: '100px' }}
								src={ConfusoIcon}
							/>
						</Body.Row>
						<Body.Text>
							A página que você está procurando não existe.
							<br />
							Mas não se preocupe, você pode acessar o menu e
							encontrar o que procura!
						</Body.Text>

						<br />
						<br />
						<br />

						<Body.BtnGrad
							onClick={() => {
								navigate('/menu');
							}}
						>
							<p>Acessar menu</p>
						</Body.BtnGrad>
					</>
				)}

				{noPermission && (
					<>
						<Body.SubTitle>
							Opa! Você não tem permissão!
						</Body.SubTitle>
						<Body.Row>
							<img
								style={{
									height: '100px',
									marginRight: '-30px',
								}}
								src={CardeiraIcon}
							/>
							<img
								style={{
									height: '100px',
									marginRight: '-30px',
									zIndex: '10',
								}}
								src={TimidoIcon}
							/>
							<img
								style={{ height: '100px' }}
								src={ProibidoIcon}
							/>
						</Body.Row>
						<Body.Text>
							A página que você está procurando existe, mas você
							não tem permissão para acessá-la.
						</Body.Text>
						<Body.Text>
							Caso acredite que isso seja um erro, entre em
							contato com o administrador do sistema.
						</Body.Text>

						<br />
						<br />
						<br />

						<Body.BtnGrad
							onClick={() => {
								navigate('/menu');
							}}
						>
							<p>Acessar menu</p>
						</Body.BtnGrad>
					</>
				)}
			</Body.CardLogin>
		</Body.Container>
	);
};

export default PageNotFound;
