import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	padding: 30px;
	display: flex;
	grid-gap: 30px 0px;
	flex-direction: column;
	justify-content: center;
	position: relative;
	overflow: scroll;
`;

export const Title = styled.h1`
	font-family: 'Comfortaa';
	color: #000;
	font-size: 20px;
	font-weight: bold;
`;

export const SubTitle = styled.h3`
	font-family: 'Comfortaa';
	font-weight: normal;
	color: #000;
	font-size: 16px;
`;

export const SubTitleCard = styled.h3`
	font-family: 'Nunito';
	font-weight: normal;
	color: #000;
	font-size: 16px;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 10px;
	align-items: center;
	flex-wrap: wrap;
`;
export const Circle = styled.div`
	width: 7px;
	height: 7px;
	background: #000;
	border-radius: 10px;
`;

export const TitleCompany = styled.h2<{ color: string }>`
	font-family: 'Signa';
	font-weight: black;
	font-style: italic;
	font-size: 18px;
	color: ${(props) => props.color};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 30px 0px;
	padding-bottom: 80px;
	padding-top: 20px;
	align-items: center;
	min-height: 385px;
`;

export const TitleContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Text = styled.p`
	font-family: 'Comfortaa';
	font-size: 12px;
	color: #000;
`;
const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`;

export const TextError = styled.p`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Red};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const CompanyLogo = styled.img`
	height: 20px;
`;

export const Card = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: left;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 10px 0px;
	text-align: center;
	margin-bottom: 20px;
`;

export const Icon = styled.img`
	width: 80px;
	height: 80px;
	min-width: 80px;
	max-width: 80px;
	min-height: 80px;
	max-height: 80px;
	border-radius: 12px;
`;


export const PageTitle = styled.a`
	text-align: center;
	font-family: 'Comfortaa';
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 10px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Scroll = styled.div`
	width: 100%;
	padding-top: 10px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	box-sizing: border-box;
	max-height: 70vh;
`;

export const HalfContent = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	max-height: 100%;
	min-width: 400px;
	transition: 0.5s;
	padding: 10px;
	box-sizing: border-box;
`;

export const HeaderButton = styled.div`
	background-color: #1080d6;
	width: 30px;
	height: 30px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		transition: 0.3s;
		opacity: 0.8;
	}
`;

export const FindContent = styled.div`
	min-width: 30px;
	position: relative;
`;

export const CardInput = styled.div`
	display: flex;
	grid-gap: 0px 10px;
	padding: 10px;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	cursor: pointer;
`;

export const Find = styled.input`
	height: 30px;
	max-width: 90%;
	width: 100%;
	border-radius: 15px;
	border: 1px solid #1080d6;
	padding-left: 30px;
	font-family: 'Nunito';
	background: none;
	z-index: 5;
`;

export const CardUnit = styled.div`
	width: 100%;
	height: 45px;
	background: #fff;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	outline: none;
	transition: 0.35s;
	display: flex;
	align-items: center;
	padding-left: 15px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const TextUnit = styled.p`
	font-family: 'Comfortaa';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	font-size: 13px;
`;

export const SelectUser = styled.div`
	grid-gap: 0px 10px;
	width: 100%;
	height: 60px;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	cursor: pointer;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	transition: 0.5s;
	:hover {
		transition: 0.5s;
		background: rgba(0, 0, 0, 0.02);
	}
`;

export const Photo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 20px;
`;


export const ButtonTitle = styled.a`
	font-family: 'Comfortaa';
	font-size: 16px;
	font-weight: bold;
	color: #000;
	max-width: 300px;
	word-wrap: break-word;
`;

export const ModalCardIcon = styled.img`
	margin-right: 15px;
	width: 47px;
	z-index: 3;
`;

const CardAnimate = keyframes`
    from{
        right: -10vw;
        opacity: 0;
    }
    to{
        right: 80px;
        opacity: 1;
    }
`;

export const ModalCard = styled.div`
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	grid-gap: 15px 0px;
	animation: 0.5s ${CardAnimate} ease-in;
	transition: 0.5s;
	background: white;

	&:hover {
		background: #ebebeb;
	}
`;


export const ToggleBtn = styled.div<{ active: boolean }>`
	width: 30px;
	height: 30px;
	margin-right: 10px;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => (props.active ? defines.BlueTec : '#FFF')};
	border: 2px solid ${(props) => (props.active ? defines.BlueTec : 'gray')};
	transition: 0.15s;
`;
export const IconCheckbox = styled.img`
	width: 15px;
`;

export const NoteText = styled.a`
	font-family: 'Comfortaa';
	font-size: 12px;
	color: #6d7989;
`;

const floatAnimation1 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-4px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const floatAnimation2 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-8px);
    }
    100% {
        transform: translatey(0px);
    }
`;

const floatAnimation3 = keyframes`
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-2px);
    }
    100% {
        transform: translatey(0px);
    }
`;

// Estilização para aplicar animações de flutuação com variações
export const FloatIcon1 = styled.img`
	animation: ${floatAnimation1} 3s ease-in-out infinite;
`;

export const FloatIcon2 = styled.img`
	animation: ${floatAnimation2} 3.2s ease-in-out infinite;
`;

export const FloatIcon3 = styled.img`
	animation: ${floatAnimation3} 2.8s ease-in-out infinite;
`;
