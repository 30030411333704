import * as Body from './Styles';

import warningIcon from '../../../assets/icons/warning-icon.svg';
import checkIcon from '../../../assets/icons/check-icon.svg';
import errorIcon from '../../../assets/icons/error-icon.svg';
import { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/store';

type NotifyProps = {
	status: number;
	title: string;
	text?: string;
};

const STATUS_CODE = {
	OK: 1,
	WARNING: 2,
	ERROR: 3,
};

const Notify = () => {
	const notifications: NotifyProps[] = useSelector(
		(state: State) => state.notifications
	);
	const dispatch = useDispatch();

	const timer = useRef(0);

	const getColor = (status: number) => {
		switch (status) {
			case STATUS_CODE.OK:
				return '#C5EB7F';
			case STATUS_CODE.WARNING:
				return '#E5D34A';
			case STATUS_CODE.ERROR:
				return '#F03F3F';
			default:
				return '#fff';
		}
	};

	const getIcon = (status: number) => {
		switch (status) {
			case STATUS_CODE.OK:
				return checkIcon;
			case STATUS_CODE.WARNING:
				return warningIcon;
			case STATUS_CODE.ERROR:
				return errorIcon;
			default:
				return '#fff';
		}
	};

	useEffect(() => {
		if (notifications.length > 0) {
			const interval = setInterval(() => {
				timer.current -= 1;

				if (timer.current === 0) {
					dispatch({ type: 'REMOVE_NOTIFICATION' });
				} else if (timer.current === -1) {
					timer.current = 10;
				}
			}, 1000);

			return function cleanup() {
				clearInterval(interval);
			};
		}
	}, [notifications, dispatch]);

	return (
		<Body.Container>
			{notifications?.map((item, index) => {
				return (
					<Body.Notify
						key={index.toString()}
						onClick={() =>
							dispatch({ type: 'REMOVE_NOTIFICATION' })
						}
						style={{ top: 'calc(20px + ' + 70 * index + 'px' }}
					>
						<Body.Circle color={getColor(item.status)}>
							<Body.Icon src={getIcon(item.status)} />
						</Body.Circle>
						<Body.TextContent>
							<Body.Title>{item.title}</Body.Title>
							{item.text ? (
								<Body.Text>{item.text}</Body.Text>
							) : null}
						</Body.TextContent>
					</Body.Notify>
				);
			})}
		</Body.Container>
	);
};

export default Notify;
