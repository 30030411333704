import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import * as Types from '../../../utils/types/Types';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Avatar from '../../../assets/images/avatar.png';
import VisitaIcon from '../../../assets/icons/visita-icon.svg';
import UsuarioIcon from '../../../assets/icons/usuario-icon.svg';
import TecnicoIcon from '../../../assets/icons/tecnico-icon.svg';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';
// import { ModalDeleteUser } from './Modals/ModalDeleteUser';
import CheckIcon from '../../../assets/icons/check-icon-white.svg';
import DeleteUser from './Modals/DeleteUser';

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	nomeEmpresa: string;
	endereco: string;
	cnpj: string;
	cnpjFilial: string;
	tipoUsuarioEmpresarial: number | null;
};

type UsuarioProps = {
	idEmpresarial: string;
	idUsuario: string;
	fotoUsuario: string | null;
	ddd: string;
	celular: string;
	nomeCompleto: string;
	email: string;
	cargo: number;
	encarregadoDados: number;
};

const EditDefaultUser = () => {
	const { state } = useLocation();
	const { user } = state as { user: UsuarioProps };

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const selectedUnity = useSelector(
		(state: State) => state.selectedUnity.selectedUnity
	);

	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<UsuarioProps[]>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [nomeCompleto, setNome] = useState(user.nomeCompleto);
	const [email, setEmail] = useState(user.email);
	const [ddd, setDDD] = useState(user.ddd);
	const [celular, setTelefone] = useState(user.celular);
	const [imagemUsuario, setImagem] = useState(user.fotoUsuario || '');
	const [nivelAcesso, setNivel] = useState(user.cargo);
	const [encarregadoDados, setEncarregado] = useState(user.encarregadoDados);
	const [idUsuario, setId] = useState(user.idUsuario);
	const [idEmpresarial, setIdEmpresarial] = useState(user.idEmpresarial);
	const [loadedUserData, setUserData] = useState<UsuarioProps>(user);
	const [selected, setSelected] = useState(user.cargo);
	const [loading, toggleLoading] = useState(false);
	const [isEncarregadoDados, toggleEncarregadoDados] = useState(
		user.encarregadoDados === 1
	);

	const inputRef = useRef<any>();

	const [errorEmail, setErrorEmail] = useState(false);

	const auxSetEmail = (value: string) => {
		if (Functions.verifyEmail(value)) {
			setEmail(value);
			setErrorEmail(false);
		} else {
			setEmail(value);
			setErrorEmail(true);
		}
	};

	const getCargo = (cargo: number) => {
		switch (cargo) {
			case defines.CargoEmpresarial['Administrador']:
				return 'Administrador';
			case defines.CargoEmpresarial['Padrão']:
				return 'Padrão';
		}
	};

	useEffect(() => {
		setNome(user.nomeCompleto);
		setEmail(user.email);
		setDDD(user.ddd);
		setTelefone(user.celular);
		setImagem(user.fotoUsuario || '');
		setId(user.idUsuario);
		setNivel(user.cargo);
		setUserData(user);
		toggleLoading(false);
	}, [dispatch, navigate]);

	const updateUser = () => {
		toggleLoading(true);
		axios
			.put(
				defines.apiURL +
					'/api/EmpresarialCRUD' +
					'?userTypeRequest=' +
					userType +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(selectedUnity),
				{
					idEmpresarial: user.idEmpresarial,
					encarregadoDados: isEncarregadoDados === true ? 1 : 0,
					nivelAcesso: nivelAcesso,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Cargo alterado com sucesso!',
				});
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	return (
		<Body.Wrapper>
			<Body.Container>
				<Sidebar
					type={userType}
					content={defines.ContentSidebar.MANAGE_COMPANY}
				/>
				<ButtonWrapper
					action={() => {
						navigate('/usuarios-empresarial');
					}}
					style={{
						position: 'absolute',
						top: '20px',
						left: '20px',
						minWidth: '50px',
						minHeight: '50px',
						backgroundColor: 'white',
						borderRadius: '50%',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 1,
						boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
					}}
				>
					<Body.CardIcon src={ArrowBack} />
				</ButtonWrapper>

				<div style={{ margin: '10px' }} />

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '300px',
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
						}}
					>
						<Body.Photo src={imagemUsuario || Avatar} />
					</div>
				</div>

				<Body.Row>
					<Input
						text={nomeCompleto}
						setText={setNome}
						placeholder="Nome Completo"
						title="Nome Completo"
						disabled
					/>
				</Body.Row>
				<Body.Row>
					<Input
						text={email}
						setText={auxSetEmail}
						placeholder="Email"
						title="Email"
						disabled
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={'20%'}
						text={ddd}
						setText={setDDD}
						placeholder="DDD"
						title="DDD"
						limit={3}
						disabled
					/>
					<Input
						width={'80%'}
						text={celular}
						setText={setTelefone}
						placeholder="Telefone"
						title="Telefone"
						limit={9}
						disabled
					/>
				</Body.Row>
				{errorEmail ? (
					<Body.TextError>
						Formato de email inválido ex: email@email.com
					</Body.TextError>
				) : null}

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '120px',
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
						}}
					>
						<Body.Row>
							<Body.Card
								onClick={() =>
									setNivel(defines.CargoEmpresarial['Padrão'])
								}
								style={{
									border:
										nivelAcesso ===
										defines.CargoEmpresarial['Padrão']
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={UsuarioIcon} />
								<Body.CardTitle>Padrão</Body.CardTitle>
							</Body.Card>
							<Body.Card
								onClick={() =>
									setNivel(
										defines.CargoEmpresarial[
											'Administrador'
										]
									)
								}
								style={{
									border:
										nivelAcesso ===
										defines.CargoEmpresarial[
											'Administrador'
										]
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={TecnicoIcon} />
								<Body.CardTitle>Administrador</Body.CardTitle>
							</Body.Card>
						</Body.Row>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: '20px 0',
					}}
					onClick={() => toggleEncarregadoDados(!isEncarregadoDados)}
				>
					<Body.ToggleBtn active={isEncarregadoDados}>
						{isEncarregadoDados ? (
							<Body.IconCheckbox src={CheckIcon} />
						) : null}
					</Body.ToggleBtn>
					<Body.Text style={{ width: 'calc(100% - 40px)' }}>
						Encarregado de Dados
					</Body.Text>
				</div>

				<Body.Row>
					<Button
						text="Salvar"
						color="blue"
						action={() => {
							updateUser();
						}}
					/>

					<Button
						text="Desvincular Usuário"
						color="yellow"
						action={() => {
							toggleDelete(true);
						}}
					/>
				</Body.Row>

				<Body.Row>
				</Body.Row>

				{loading && <Loading />}
				{modalDelete && (
					<DeleteUser
						toggle={toggleDelete}
						usuario={user}
						nomeFilial={'atual'}
						idFilial={selectedUnity}
						regenerate={() => {}}
					/>
				)}
			</Body.Container>
		</Body.Wrapper>
	);
};

export default EditDefaultUser;
