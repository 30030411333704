import styled, { keyframes } from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

const getColor = (value: string) => {
	switch (value) {
		case 'blue':
			return defines.BlueTec;
		case 'red':
			return defines.Red;
		case 'yellow':
			return defines.Yellow;
		case 'green':
			return defines.GreenTec;
		case 'gray':
			return '#BBBBBB';
		default:
			return defines.Red;
	}
};

export const Button = styled.button<{
	color?: string;
	width?: number;
	disable?: boolean;
}>`
	height: 40px;
	width: ${(props) => (props.width ? props.width + 'px' : '100%')};
	background-color: ${(props) =>
		props.color ? getColor(props.color) : defines.BlueTec};
	border: 1px solid
		${(props) => (props.color ? getColor(props.color) : defines.BlueTec)};
	color: #fff;
	border-radius: 25px;
	padding: 12px;
	box-sizing: border-box;
	transition: 0.35s;
	cursor: pointer;
	diplay: flex;
	align-items: center;
	justify-content: center;
	:hover {
		filter: ${(props) =>
			props.disabled ? 'brightness(90%)' : 'brightness(100%)'};
		transition: 0.5s;
	}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.img`
	height: 30px;
	width: 30px;
	animation: ${rotate} 0.8s linear infinite;
	position: absolute;
	right: calc(50% - 15px);
	top: 5px;
`;

export const Content = styled.div<{ width?: number }>`
	width: ${(props) => (props.width ? props.width + 'px' : '100%')};
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;
