import React, { useState, useRef, useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Types from '../../../utils/types/Types';
import * as Functions from '../../../utils/functions/Functions';
import * as Modals from './modals/Modals';
import axios from 'axios';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

const ManageCompanies = () => {
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const [loadingChange, toggleChange] = useState(false);
	const inputCompanyRef = useRef<any>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modalAddCompany, toggleModalCompany] = useState(false);

	const [hasLenght, toggleHasLength] = useState(false);
	const [hasCompany, toggleHasCompany] = useState(false);
	const [companies, setCompanies] = useState<Types.EmpresaInfo[]>([]);
	const [nomeEmpresa, setEmpesa] = useState('');
	const [cnpj, setCNPJ] = useState('');
	const [idEmpresa, setId] = useState('');
	const [filteredCompanies, setFilteredCompanies] = useState<
		Types.EmpresaInfo[]
	>([]);

	const [modalDelete, toggleDelete] = useState(false);

	const getCompanies = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeEmpresa'
				);
				setCompanies(sorted);
				setFilteredCompanies(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeEmpresa'
				);
				setCompanies(sorted);
				setFilteredCompanies(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	}, [dispatch, navigate]);

	const selectCompany = (company: Types.EmpresaInfo) => {
		if (idEmpresa === company.idEmpresa && hasCompany) {
			toggleHasCompany(false);
			return;
		}
		setId(company.idEmpresa);
		setCNPJ(company.cnpj);
		setEmpesa(company.nomeEmpresa);
		toggleHasCompany(true);
	};

	const filterCompanyData = (name: string) => {
		if (name.length === 0) {
			setFilteredCompanies(companies);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = companies.filter((item) =>
			item.nomeEmpresa.toUpperCase().includes(name.toUpperCase())
		);
		setFilteredCompanies(filtered!);
	};

	const actionDelete = () => {
		getCompanies();
		toggleHasCompany(false);
	};

	const updateCompany = () => {
		if (nomeEmpresa.length === 0 || cnpj.length === 0) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: '',
				text: 'Preencha todos os campos',
			});
			return;
		}
		toggleChange(true);
		axios
			.put(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ idEmpresa, nomeEmpresa, cnpjEmpresa: cnpj },
				{ withCredentials: true }
			)
			.then((response) => {
				getCompanies();
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Dados da empresa alterados com sucesso',
				});
				toggleChange(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleChange(false);
			});
	};

	const editUnit = () => {
		navigate('/empresas/filiais', {
			state: { idEmpresa: idEmpresa, nome: nomeEmpresa },
		});
	};

	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
			/>
			<Body.Content>
				<Body.Row style={{ width: '100%', height: '100%' }}>
					<Body.HalfContent
						style={{
							width: hasCompany ? 'calc(100% - 120px)' : '100%',
						}}
					>
						<Body.Row
							style={{
								width: '100%',
								height: '120px',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Breadcrumb />
							<Body.Row
								style={{ maxWidth: '90%', minWidth: '50px' }}
							>
								<Body.FindContent>
									<Body.Find
										style={
											hasLenght ? { width: '300px' } : {}
										}
										onChange={(e) =>
											filterCompanyData(e.target.value)
										}
										ref={inputCompanyRef}
									/>
									<Body.Icon
										src={SearchIcon}
										style={{ cursor: 'text' }}
										onClick={() =>
											inputCompanyRef.current.focus()
										}
									/>
								</Body.FindContent>
								{acessLevel !==
								defines.CargoTectrol['Padrão'] ? (
									<Body.HeaderButton
										onClick={() => toggleModalCompany(true)}
									>
										<Body.Icon
											src={AddIcon}
											style={{
												position: 'relative',
												top: 0,
												left: 0,
											}}
										/>
									</Body.HeaderButton>
								) : null}
							</Body.Row>
						</Body.Row>
						<Body.Scroll style={{ maxHeight: 'calc(100% - 80px)' }}>
							{filteredCompanies.length === 0 ? (
								<Body.Text>
									Nenhuma empresa encontrada
								</Body.Text>
							) : (
								filteredCompanies.map((item, index) => (
									<Body.SelectUser
										onClick={() => selectCompany(item)}
										key={index.toString()}
									>
										<Body.Text>
											{item.nomeEmpresa}
										</Body.Text>
									</Body.SelectUser>
								))
							)}
						</Body.Scroll>
					</Body.HalfContent>

					{hasCompany ? (
						<Body.HalfContent
							style={{
								alignItems: 'center',
								width: hasCompany ? '100px' : '0px',
							}}
						>
							<Input
								text={nomeEmpresa}
								setText={setEmpesa}
								title="Nome"
								disabled={
									acessLevel ===
									defines.CargoTectrol['Padrão']
								}
							/>
							<Input
								text={formatCNPJ(cnpj)}
								setText={setCNPJ}
								title="CNPJ"
								disabled
							/>
							<Button
								color="yellow"
								text={
									acessLevel !==
									defines.CargoTectrol['Padrão']
										? 'Editar Filiais'
										: 'Visualizar Filiais'
								}
								action={() => {
									editUnit();
								}}
							/>
							{acessLevel === defines.CargoTectrol['Suporte'] || acessLevel === defines.CargoTectrol['Engenheiro'] ? (
								<>
									<Button
										text="Salvar"
										action={() => updateCompany()}
										loading={loadingChange}
									/>
									<Button
										text="Excluir"
										color="red"
										action={() => toggleDelete(true)}
										disabled={loadingChange}
									/>
								</>
							) : null}
						</Body.HalfContent>
					) : null}
				</Body.Row>
			</Body.Content>
			{loading ? <Loading /> : null}
			{modalAddCompany ? (
				<Modals.AddCompany
					toggle={toggleModalCompany}
					getData={getCompanies}
				/>
			) : null}
			{modalDelete ? (
				<Modals.ConfirmDeleteCompany
					nome={nomeEmpresa}
					id={idEmpresa}
					toggle={toggleDelete}
					action={actionDelete}
				/>
			) : null}
		</Body.Container>
	);
};

export default ManageCompanies;
