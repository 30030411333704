import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/store';
import DHMIcon from '../../../assets/icons/dhm-icon.png';
import ChartIcon from '../../../assets/icons/chart-icon.png';
import IndustryIcon from '../../../assets/icons/industry-icon.png';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Functions from '../../../utils/functions/Functions';

type VisitorProps = {
	idFilial: string;
	nomeFilial: string;
	validade: string;
	permissaoEscrita: number;
	api: string;
};

const VisitingConnectedApps: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [visitors, setVisitors] = useState<VisitorProps[]>([]);
	const userType = useSelector((state: State) => state.userType.userType);

	useEffect(() => {
		setLoading(true);
		axios
			.get(
				`${defines.apiURL}/api/AcessoVisitante?userTypeRequest=${userType}`,
				{ withCredentials: true }
			)
			.then((response) => {
				setVisitors(response.data.content);
			})
			.catch((error) => {
				const { status, data } = error.response;
				Functions.ResolveErrors(
					status,
					data.code,
					data.message,
					navigate,
					dispatch,
					data.content
				);
			})
			.finally(() => setLoading(false));
	}, [dispatch, navigate, userType]);

	const formatDate = (dataIso: string) => {
		const date = new Date(dataIso);
		return date.toLocaleDateString('pt-BR', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
	};

	const getApi = (api: string) => {
		switch (api) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				return 'Unknown API'; // Handle unknown API
		}
	};

	const getApiIcon = (api: string) => {
		switch (api) {
			case 'DHM':
				return DHMIcon;
			case 'REL':
				return ChartIcon;
			case 'INS':
				return IndustryIcon;
			default:
				return null; // Handle unknown API icon
		}
	};

	const openApp = (link: string) => {
		window.open(link, '_blank');
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.CONNECTED_APPS}
			/>

			<Body.FullWidthDiv>
				<Body.SubTitle>
					Aqui está o histórico de aplicações nas quais você aceitou o
					convite para visitar.
				</Body.SubTitle>
				<Body.SubTitle>
					Clique na aplicação para abrir o site dela.
				</Body.SubTitle>

				<br />

				{visitors.length === 0 ? (
					<Body.Text>Nenhuma aplicação conectada</Body.Text>
				) : (
					visitors.map((visitor) => (
						<Body.Card key={visitor.idFilial}>
							{' '}
							{/* Use unique key */}
							<ButtonWrapper
								style={{ width: '100%' }}
								action={() => {
									if (visitor.api === 'DHM') {
										openApp(
											'https://www.dhm.dinamoautomacao.com.br'
										);
									}
								}}
							>
								<Body.Row>
									<Body.Icon
										src={getApiIcon(visitor.api) || ''}
									/>
									<Body.CenteredDiv>
										<Body.AppTitleLeftWrapper>
											<Body.AppTitleLeft>
												{getApi(visitor.api)}
											</Body.AppTitleLeft>
											<Body.AppTitleLeft
												style={{
													fontWeight: 'normal',
													color: 'grey',
												}}
											>
												&nbsp;na filial&nbsp;
											</Body.AppTitleLeft>
											<Body.AppTitleLeft>
												{visitor.nomeFilial}
											</Body.AppTitleLeft>
										</Body.AppTitleLeftWrapper>
										<Body.RightAlignedText>
											Acesso até{' '}
											{formatDate(visitor.validade)}
										</Body.RightAlignedText>
									</Body.CenteredDiv>
								</Body.Row>
							</ButtonWrapper>
						</Body.Card>
					))
				)}
			</Body.FullWidthDiv>
		</Body.Container>
	);
};

export default VisitingConnectedApps;
