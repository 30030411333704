import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import CodeInput from '../../components/Inputs/InputCode/Input';
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg';
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg';
import * as Functions from '../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LogoConecta from '../../../assets/images/logoConecta.png';
import BrilhoIcon from '../../../assets/icons/brilho-icone.png';
import ProgramadoraIcon from '../../../assets/icons/programadora-icone.png';
import SurpresoIcon from '../../../assets/icons/surpreso-icone.png';
import ConstrucaoIcon from '../../../assets/icons/construcao-icone.png';
import PlacaConstrucaoIcon from '../../../assets/icons/placa-construcao-icone.png';
import DesenvolvimentoIcon from '../../../assets/icons/desenvolvimento-icone.png';
import ConfusoIcon from '../../../assets/icons/confuso-icon.png';
import CarteiroIcon from '../../../assets/icons/carteiro-icon.png';
import ProibidoIcon from '../../../assets/icons/proibido-icon.png';
import CardeiraIcon from '../../../assets/icons/carteira-icone.png';
import TimidoIcon from '../../../assets/icons/timido-icone.png';

type PageNotFoundProps = {
	notFound?: boolean;
	noPermission?: boolean;
};

const PageNotFound: React.FC<PageNotFoundProps> = ({
	notFound = false,
	noPermission = false,
}) => {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	return (
		<Body.Container>
			<Body.Row style={{ justifyContent: 'center', marginTop: '30px' }}>
				<Body.CompanyLogo
					style={{ height: '80px' }}
					src={LogoConecta}
				/>
			</Body.Row>
			<Body.Card>
			{notFound && (
					<>
						<Body.SubTitle>Não há nada aqui!</Body.SubTitle>
						<Body.Row>
							<Body.FloatIcon1
								style={{
									height: '100px',
									marginRight: '-60px',
								}}
								src={ProibidoIcon}
							/>
							<Body.FloatIcon2
								style={{
									height: '100px',
									marginRight: '-50px',
									zIndex: '10',
								}}
								src={CarteiroIcon}
							/>
							<Body.FloatIcon3
								style={{ height: '100px' }}
								src={ConfusoIcon}
							/>
						</Body.Row>
						<Body.Text>
							A página que você está procurando não existe.
							<br />
							Mas não se preocupe, você pode acessar o menu e
							encontrar o que procura!
						</Body.Text>

						<br />
						<br />
						<br />

						<Body.BtnGrad
							onClick={() => {
								navigate('/menu');
							}}
						>
							<p>Acessar Menu</p>
						</Body.BtnGrad>
					</>
				)}

				{noPermission && (
					<>
						<Body.SubTitle>
							Opa! Você não tem permissão!
						</Body.SubTitle>
						<Body.Row>
							<Body.FloatIcon1
								style={{
									height: '100px',
									marginRight: '-30px',
								}}
								src={CardeiraIcon}
							/>
							<Body.FloatIcon2
								style={{
									height: '100px',
									marginRight: '-30px',
									zIndex: '10',
								}}
								src={TimidoIcon}
							/>
							<Body.FloatIcon3
								style={{ height: '100px' }}
								src={ProibidoIcon}
							/>
						</Body.Row>
						<Body.Text>
							A página que você está procurando existe, mas você
							não tem permissão para acessá-la.
						</Body.Text>
						<Body.Text>
							Caso acredite que isso seja um erro, entre em
							contato com o administrador do sistema.
						</Body.Text>


						<Body.BtnGrad
							onClick={() => {
								navigate('/menu');
							}}
						>
							<p>Acessar Menu</p>
						</Body.BtnGrad>
					</>
				)}
			</Body.Card>
		</Body.Container>
	);
};

export default PageNotFound;
