import React, { useState, useRef, useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Types from '../../../utils/types/Types';
import * as Functions from '../../../utils/functions/Functions';
import * as Modals from './modals/Modals';
import axios from 'axios';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

const ManageUnities = () => {
	const location = useLocation();
	const { idEmpresa, nome } = location.state || {};

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [loading, toggleLoading] = useState(false);
	const [loadingChange, toggleChange] = useState(false);
	const inputCompanyRef = useRef<any>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modalAddUnity, toggleModalUnity] = useState(false);

	const [hasLenght, toggleHasLength] = useState(false);
	const [hasUnity, toggleHasUnity] = useState(false);
	const [unities, setUnities] = useState<Types.FilialInfo[]>([]);

	const [idFilial, setIdFilial] = useState('');
	const [nomeEmpresa, setEmpesa] = useState('');
	const [nomeFilial, setNomeFilial] = useState('');
	const [endereco, setEndereco] = useState('');
	const [cnpj, setCNPJ] = useState('');

	const [filteredUnities, setFilteredUnities] = useState<Types.FilialInfo[]>(
		[]
	);

	const [modalDelete, toggleDelete] = useState(false);

	const getUnities = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/FilialCRUD?idEmpresa=' +
					Functions.ReplaceSpecialCharacters(idEmpresa) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeFilial'
				);
				setUnities(sorted);
				setFilteredUnities(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/FilialCRUD?idEmpresa=' +
					Functions.ReplaceSpecialCharacters(idEmpresa) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeEmpresa'
				);
				setUnities(sorted);
				setFilteredUnities(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	}, [dispatch, navigate]);

	const selectUnity = (unity: Types.FilialInfo) => {
		if (idEmpresa === unity.idEmpresa && hasUnity) {
			toggleHasUnity(false);
			return;
		}
		setIdFilial(unity.idFilial);
		setCNPJ(unity.cnpj);
		setEmpesa(unity.nomeEmpresa);
		setNomeFilial(unity.nomeFilial);
		setEndereco(unity.endereco);
		toggleHasUnity(true);
	};

	const filterUnityData = (name: string) => {
		if (name.length === 0) {
			setFilteredUnities(unities);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = unities.filter((item) =>
			item.nomeFilial.toUpperCase().includes(name.toUpperCase())
		);
		setFilteredUnities(filtered!);
	};

	const actionDelete = () => {
		getUnities();
		toggleHasUnity(false);
	};

	const updateUnity = () => {
		if (nomeFilial.length === 0 || endereco.length === 0) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: '',
				text: 'Preencha todos os campos',
			});
			return;
		}
		toggleLoading(true);
		toggleChange(true);
		axios
			.put(
				defines.apiURL + '/api/FilialCRUD?userTypeRequest=' + userType,
				{ idFilial, nomeFilial, endereco },
				{ withCredentials: true }
			)
			.then((response) => {
				getUnities();
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Dados da filial alterados com sucesso',
				});
				toggleChange(false);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleChange(false);
				toggleLoading(false);
			});
	};

	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
			/>
			<Body.Content>
				<Body.Row style={{ width: '100%', height: '100%' }}>
					<Body.HalfContent
						style={{
							width: hasUnity ? 'calc(100% - 120px)' : '100%',
						}}
					>
						<Body.Row
							style={{
								width: '100%',
								height: '120px',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Breadcrumb empresa={nome} />
							<Body.Row
								style={{ maxWidth: '90%', minWidth: '50px' }}
							>
								<Body.FindContent>
									<Body.Find
										style={
											hasLenght ? { width: '300px' } : {}
										}
										onChange={(e) =>
											filterUnityData(e.target.value)
										}
										ref={inputCompanyRef}
									/>
									<Body.Icon
										src={SearchIcon}
										style={{ cursor: 'text' }}
										onClick={() =>
											inputCompanyRef.current.focus()
										}
									/>
								</Body.FindContent>
								{acessLevel !==
								defines.CargoTectrol['Padrão'] ? (
									<Body.HeaderButton
										onClick={() => toggleModalUnity(true)}
									>
										<Body.Icon
											src={AddIcon}
											style={{
												position: 'relative',
												top: 0,
												left: 0,
											}}
										/>
									</Body.HeaderButton>
								) : null}
							</Body.Row>
						</Body.Row>
						<Body.Scroll style={{ maxHeight: 'calc(100% - 80px)' }}>
							{filteredUnities.length === 0 ? (
								<Body.Text>Nenhuma filial encontrada</Body.Text>
							) : (
								filteredUnities.map((item, index) => (
									<Body.SelectUser
										onClick={() => selectUnity(item)}
										key={index.toString()}
									>
										<Body.Text>{item.nomeFilial}</Body.Text>
									</Body.SelectUser>
								))
							)}
						</Body.Scroll>
					</Body.HalfContent>

					{hasUnity ? (
						<Body.HalfContent
							style={{
								alignItems: 'center',
								width: hasUnity ? '100px' : '0px',
							}}
						>
							<Input
								text={nomeFilial}
								setText={setNomeFilial}
								title="Nome da Filial"
								disabled={
									acessLevel ===
									defines.CargoTectrol['Padrão']
								}
							/>
							<Input
								text={endereco}
								setText={setEndereco}
								title="Endereço da Filial"
								disabled={
									acessLevel ===
									defines.CargoTectrol['Padrão']
								}
							/>
							<Input
								text={formatCNPJ(cnpj)}
								setText={setCNPJ}
								title="CNPJ da Filial"
								disabled
							/>
							<Button
								color="yellow"
								text={
									acessLevel !==
									defines.CargoTectrol['Padrão']
										? 'Gerenciar Usuários'
										: 'Visualizar Usuários'
								}
								action={() => {
									navigate('/empresas/filiais/usuarios', {
										state: {
											idEmpresa: idEmpresa,
											nomeEmpresa: nomeEmpresa,
											idFilial: idFilial,
											nomeFilial: nomeFilial,
										},
									});
								}}
							/>
							{acessLevel !== defines.CargoTectrol['Padrão'] && (
								<>
									<Button
										text="Salvar"
										action={() => updateUnity()}
										loading={loadingChange}
									/>
									<Button
										text="Excluir"
										color="red"
										action={() => toggleDelete(true)}
										disabled={loadingChange}
									/>
								</>
							)}
						</Body.HalfContent>
					) : null}
				</Body.Row>
			</Body.Content>
			{loading ? <Loading /> : null}
			{modalAddUnity ? (
				<Modals.CreateUnity
					toggle={toggleModalUnity}
					getData={getUnities}
					idEmpresa={idEmpresa}
				/>
			) : null}
			{modalDelete ? (
				<Modals.ConfirmDeleteUnity
					nome={nomeFilial}
					id={idFilial}
					toggle={toggleDelete}
					action={actionDelete}
				/>
			) : null}
		</Body.Container>
	);
};

export default ManageUnities;
