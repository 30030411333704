import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import CodeInput from '../../components/Inputs/InputCode/Input';
import * as Functions from '../../../utils/functions/Functions';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg';
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg';

const Content = {
	SEND: 1,
	RECOVER: 3,
};

type SendProps = {
	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
	setNumero: Dispatch<SetStateAction<string>>;
	toggleContent: Dispatch<SetStateAction<number>>;
};

type RedefineProps = {
	email: string;
	numero: string;
};

const Send: React.FC<SendProps> = ({
	email,
	setEmail,
	setNumero,
	toggleContent,
}) => {
	const navigate = useNavigate();
	const [loading, toggleLoading] = useState(false);

	const [errorEmail, toggleErrorEmail] = useState(false);
	const dispatch = useDispatch();

	const trySend = () => {
		if (!Functions.verifyEmail(email)) {
			toggleErrorEmail(true);
			return;
		}
		toggleLoading(true);
		axios
			.get(defines.apiURL + '/api/ForgottenPassword?email=' + email)
			.then((response) => {
				toggleLoading(false);
				toggleContent(Content.RECOVER);
			})
			.catch((error) => {
				toggleLoading(false);
				switch (error.response.status) {
					case 406:
						if (error.response.data.code === 'TFA-3') {
							toggleContent(Content.RECOVER);
							return;
						}
				}
				// dispatch({type: 'ADD_NOTIFICATION', status: 3, title: 'Erro', text: 'não é possível recuperar a senha para o email informado'})
			});
	};

	return (
		<Body.CardLogin>
			<Body.SubTitle>Recuperar senha</Body.SubTitle>
			<Input
				text={email}
				setText={setEmail}
				placeholder="Email"
				error={errorEmail}
				action={() => trySend()}
			/>
			{errorEmail ? (
				<Body.TextError>
					Formato de email inválido ex: email@email.com
				</Body.TextError>
			) : null}
			<Button text="Enviar" action={() => trySend()} loading={loading} />
			<ButtonWithoutBG
				text="Voltar para o login?"
				action={() => navigate('/')}
			/>
		</Body.CardLogin>
	);
};

const Redefine: React.FC<RedefineProps> = ({ email, numero }) => {
	const navigate = useNavigate();

	const [tfaCode, setCode] = useState('');
	const [novaSenha, setSenha] = useState('');
	const [loading, toggleLoading] = useState(false);
	const [loadingCode, toggleCode] = useState(false);

	const [time, setTime] = useState(120);
	const [lock, toggleLock] = useState(true);

	const [hasLow, toggleHasLow] = useState(false);
	const [hasUpper, toggleHasUpper] = useState(false);
	const [hasSpecial, toggleHasSpecial] = useState(false);
	const [hasLength, toggleHasLength] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (time === 0) {
			toggleLock(false);
			return;
		}
		const interval = setInterval(() => {
			setTime((seconds) => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [time]);

	const getTime = () => {
		if (time === 120) {
			return '02:00';
		}
		if (time > 59) {
			if (time < 70) {
				return '01:0' + (time - 60);
			}
			return '01:' + (time - 60);
		} else {
			if (time < 10) {
				return '00:0' + time;
			}
			return '00:' + time;
		}
	};

	const tryResend = () => {
		toggleCode(true);
		axios
			.get(defines.apiURL + '/api/ForgottenPassword?email=' + email)
			.then((response) => {
				toggleLock(true);
				toggleCode(false);
				setTime(120);
				toggleLoading(false);
			})
			.catch((error) => {
				toggleCode(false);
				toggleLoading(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 3,
					title: 'Erro',
					text: error.response.data.message,
				});
			});
	};

	const fieldPassword = (value: string) => {
		setSenha(value);

		const verify = Functions.checkPasswordRestrictions(value);

		toggleHasLow(verify.lowercase);
		toggleHasUpper(verify.uppercase);
		toggleHasSpecial(verify.special);
		toggleHasLength(verify.lenght);
	};

	const tryRedefine = () => {
		if (!hasLow || !hasSpecial || !hasLength || !hasUpper) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: 'Erro',
				text: 'A senha informada não cumpre os requisitos',
			});
			return;
		}
		toggleLoading(true);
		axios
			.post(defines.apiURL + '/api/ForgottenPassword', {
				novaSenha,
				email,
				tfaCode,
			})
			.then((response) => {
				toggleLoading(false);
				navigate('/');
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: 'Sucesso',
					text: 'Senha redefinida com sucesso',
				});
			})
			.catch((error) => {
				toggleLoading(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 3,
					title: 'Erro',
					text: error.response.data.message,
				});
			});
	};

	return (
		<Body.CardLogin>
			<Body.SubTitle>Recuperar senha</Body.SubTitle>
			<Body.Text>
				<strong>Enviamos um código para seu email.</strong>
				<br />
				Se o email cadastrado estiver correto, em instantes você irá
				recebe-lo, insira-o para prosseguir com a redefinição.
			</Body.Text>
			<CodeInput setText={setCode} />
			<Input
				password={true}
				text={novaSenha}
				placeholder="Nova senha"
				setText={fieldPassword}
				action={() => tryRedefine()}
			/>
			<Body.Text>
				A sua senha deve conter: letras
				<strong style={{ color: hasLow ? 'green' : 'red' }}>
					{' '}
					minúsculas,{' '}
				</strong>
				<strong style={{ color: hasUpper ? 'green' : 'red' }}>
					{' '}
					maiúsculas,{' '}
				</strong>
				<strong style={{ color: hasLength ? 'green' : 'red' }}>
					{' '}
					no minímo 12 caracters,{' '}
				</strong>
				<strong style={{ color: hasSpecial ? 'green' : 'red' }}>
					{' '}
					caracteres especiais.{' '}
				</strong>
			</Body.Text>
			<Button
				text="Redefinir"
				action={() => tryRedefine()}
				loading={loading}
			/>
			{lock ? (
				<Body.Text>
					Aguarde {getTime()}s para enviar novamente
				</Body.Text>
			) : (
				<ButtonWithoutBG
					text="Reenviar código"
					loading={loadingCode}
					action={() => tryResend()}
				/>
			)}
		</Body.CardLogin>
	);
};

const Recover = () => {
	const [params] = useSearchParams();
	const emailParam = params.get('email');

	const [email, setEmail] = useState('');
	const [numero, setNumero] = useState('');

	const [content, toggleContent] = useState(Content.SEND);

	const getContent = () => {
		switch (content) {
			case Content.SEND:
				return (
					<Send
						email={email}
						setEmail={setEmail}
						setNumero={setNumero}
						toggleContent={toggleContent}
					/>
				);
			case Content.RECOVER:
				return <Redefine email={email} numero={numero} />;
		}
	};

	useEffect(() => {
		if (emailParam) {
			setEmail(emailParam);
		}
	}, [emailParam]);

	return (
		<Body.Container>
			<Body.Row>
				<Body.CompanyLogo
					style={{ height: '23px', marginLeft: '20px' }}
					src={DinamoLogo}
				/>
				<Body.CompanyLogo src={TectrolLogo} />
			</Body.Row>
			<Body.Title>Conecta</Body.Title>
			{getContent()}
		</Body.Container>
	);
};

export default Recover;
