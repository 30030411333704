import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from '../redux/store';
import Login from '../pages/Login/Login';
import Notify from '../components/Notify/Notify';
import Recover from '../pages/recover/Recover';
import ActivateAccount from '../pages/activateAccount/ActivateAccount';
import MyAccount from '../pages/myAccount/MyAccount';
import Alert from '../components/Alert/Alert';
import Privacy from '../pages/privacy/Privacy';
import PrivacyCompany from '../pages/privacyCompany/PrivacyCompany';
import NotAvailable from '../pages/notAvailable/NotAvailable';
import AccessMenu from '../pages/accessMenu/accessMenu';
import Authentication from '../pages/authentication/Authentication';
import SelectUnity from '../components/Modals/SelectUnity/SelectUnity';
import VisitingConnectedApps from '../pages/visitingConnectedApps/visitingConnectedApps';
import VisitingInvites from '../pages/visitingInvites/visitingInvites';
import TectrolUserList from '../pages/tectrolUserList/tectrolUserList';
import EditTectrolUser from '../pages/editTectrolUser/editTectrolUser';
import CompanyList from '../pages/companyList/companyList';
import EditCompany from '../pages/editCompany/editCompany';
import UnityList from '../pages/unityList/unityList';
import EditUnity from '../pages/editUnity/editUnity';
import UserList from '../pages/userList/userList';
import EditUser from '../pages/editUser/editUser';
import DefaultUserList from '../pages/defaultUserList/defaultUserList';
import EditDefaultUser from '../pages/editDefaultUser/editDefaultUser';
import CreateVisitingUser from '../pages/createUser/createUser';
import AppsPlans from '../pages/appsPlans/appsPlans';
import ConectedApps from '../pages/conectedApps/conectedApps';
import ManageSubscription from '../pages/manageSubscription/manageSubscription';
import DefaultManageSubscription from '../pages/defaultManageSubscription/defaultManageSubscription';
import PageNotFound from '../pages/notFound/NotFound';

const RoutesMobile = () => {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/recuperar" element={<Recover />} />
					<Route path="/ativar-conta" element={<ActivateAccount />} />
					<Route path="/privacidade" element={<Privacy />} />
					<Route
						path="/privacidade-empresa"
						element={<PrivacyCompany />}
					/>

					<Route path="/menu" element={<AccessMenu />} />
					<Route
						path="/menu/vincular-filial"
						element={<AccessMenu fromEmailInvite />}
					/>

					<Route
						path="/cadastro"
						element={<CreateVisitingUser />}
					/>

					<Route path="/minha-conta" element={<MyAccount />} />

					<Route
						path="/apps-conectados-visitante"
						element={<VisitingConnectedApps />}
					/>
					<Route
						path="/convites-visitante"
						element={<VisitingInvites />}
					/>

					<Route path="/tectrol" element={<TectrolUserList />} />
					<Route path="/tectrol/editar" element={<EditTectrolUser />} />

					<Route path="/empresas" element={<CompanyList />} />
					<Route path="/empresas/editar" element={<EditCompany />} />

					<Route path="/filiais" element={<UnityList />} />
					<Route path="/filiais/editar" element={<EditUnity />} />

					<Route path="/usuarios" element={<UserList />} />
					<Route path="/usuarios/editar" element={<EditUser />} />

					<Route path="/usuarios-empresarial" element={<DefaultUserList />} />
					<Route path="/usuarios-empresarial/editar" element={<EditDefaultUser />} />

					<Route path="/aplicativos" element={<AppsPlans />} />
					<Route path="/apps-conectados" element={<ConectedApps />} />
					<Route path="/apps-conectados/assinaturas" element={<DefaultManageSubscription />} />

					<Route path="/aplicativos/assinaturas" element={<ManageSubscription />} />

					<Route path="*" element={<PageNotFound notFound />} />
						<Route
							path="/permissao"
							element={<PageNotFound noPermission />}
						/>
				</Routes>
				<Notify />
				<Alert />
				<Authentication />
				<SelectUnity />
			</Provider>
		</BrowserRouter>
	);
};

export default RoutesMobile;
