import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import { State } from '../../../redux/store';

const TipoUsuario = {
	Tecnico: 0,
	Suporte: 1,
};

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	email: string;
	visitante?: number;
	cargo: number;
	idVisitante: string;
	idAPI: string;
	validade: string;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	usuario: UsuarioProps;
	filial: FilialProps;
	regenerate: () => void;
	app: string;
};

const EditVisitingUser: React.FC<SelectUnitProps> = ({
	toggle,
	usuario,
	filial,
	regenerate,
	app,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedRole, setSelectedRole] = useState(-1);
	const [validade, setValidade] = useState(
		usuario.validade || new Date().toISOString().split('T')[0]
	);
	const [validadeUTC, setValidadeUTC] = useState(
		usuario.validade || new Date().toISOString().split('T')[0]
	);

	const handleSelectRole = (role: number) => {
		setSelectedRole(role);
	};

	useEffect(() => {}, [navigate, dispatch]);

	const handleSave = () => {
		axios
			.put(
				defines.apiURL +
					'/api/UsuarioVisitanteAPI' +
					'?userTypeRequest=' +
					userType +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial),
				{
					idUsuarioVisitante: usuario.idVisitante,
					validade: validadeUTC,
					permissaoEscrita: selectedRole,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				toggle(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Tipo alterado com sucesso!',
				});
				regenerate();
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	return (
		<Modal
			title={'Editar usuário visitante - ' + getAplicativo(app)}
			toggleModal={toggle}
			loading={loading}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				<Body.Row>
					<Input
						title="Nome Completo"
						text={usuario.nomeCompleto}
						setText={() => {}}
						placeholder="Nome Completo"
						disabled
					/>
					<DatetimeInput
						title="Conceder acesso até"
						text={validade}
						setText={setValidade}
						removeHours
					/>
				</Body.Row>
				<br />

				<ButtonWrapper
					style={{ width: '100%' }}
					action={() => handleSelectRole(TipoUsuario.Tecnico)}
				>
					<Body.ModalCard
						style={{
							backgroundColor:
								selectedRole === TipoUsuario.Tecnico
									? '#ebebeb'
									: 'white',
						}}
					>
						<Body.Row>
							<Body.ModalCardIcon src={AdministradorIcon} />
							<Body.Column>
								<Body.ButtonTitle>
									Técnico Visitante
								</Body.ButtonTitle>
								<Body.Text>
									Apenas visualizar funcionamento do sistema.
								</Body.Text>
							</Body.Column>
						</Body.Row>
					</Body.ModalCard>
				</ButtonWrapper>
				<ButtonWrapper
					style={{ width: '100%' }}
					action={() => handleSelectRole(TipoUsuario.Suporte)}
				>
					<Body.ModalCard
						style={{
							backgroundColor:
								selectedRole === TipoUsuario.Suporte
									? '#ebebeb'
									: 'white',
						}}
					>
						<Body.Row>
							<Body.ModalCardIcon src={AdministradorIcon} />
							<Body.Column>
								<Body.ButtonTitle>
									Suporte Visitante
								</Body.ButtonTitle>
								<Body.Text>
									Visualizar funcionamento e adicionar
									intervenções.
								</Body.Text>
							</Body.Column>
						</Body.Row>
					</Body.ModalCard>
				</ButtonWrapper>

				<Body.Row>
					<Button
						text="Salvar"
						action={() => {
							handleSave();
						}}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};

export default EditVisitingUser;
