import styled from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

export const Input = styled.input<{ error: boolean; width?: number }>`
	width: ${(props) => (props.width ? props.width + 'px' : '100%')};
	height: 45px;
	background: #fff;
	border-radius: 12px;
	font-family: 'Comfortaa';
	border: 1px solid
		${(props) => (props.error ? defines.Red : 'rgba(200,200,200,0.5);')};
	outline: none;
	transition: 0.35s;
	padding-left: 15px;
	box-sizing: border-box;
	:focus {
		border: solid 1px ${defines.BlueTec};
		padding-left: 20px;
		transition: 0.35s;
	}
`;

export const Icon = styled.img`
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	right: 20px;
	bottom: 13px;
`;

export const Content = styled.div<{ width?: number }>`
	width: ${(props) => (props.width ? props.width + 'px' : '100%')};
	display: flex;
	flex-direction: column;
	position: relative;
`;
export const Text = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
