import { createGlobalStyle } from 'styled-components';

import ComfortaaLight from './assets/fonts/Comfortaa/Comfortaa-Light.ttf';
import ComfortaaRegular from './assets/fonts/Comfortaa/Comfortaa-Regular.ttf';
import ComfortaaBold from './assets/fonts/Comfortaa/Comfortaa-Bold.ttf';
import ComfortaaMedium from './assets/fonts/Comfortaa/Comfortaa-Medium.ttf';
import ComfortaaSemiBold from './assets/fonts/Comfortaa/Comfortaa-SemiBold.ttf';

import NunitoExtraLight from './assets/fonts/Nunito/Nunito-ExtraLight.ttf';
import NunitoLight from './assets/fonts/Nunito/Nunito-Light.ttf';
import NunitoRegular from './assets/fonts/Nunito/Nunito-Regular.ttf';
import NunitoItalic from './assets/fonts/Nunito/Nunito-Italic.ttf';
import NunitoSemiBold from './assets/fonts/Nunito/Nunito-SemiBold.ttf';
import NunitoSemiBoldItalic from './assets/fonts/Nunito/Nunito-SemiBoldItalic.ttf';
import NunitoBold from './assets/fonts/Nunito/Nunito-Bold.ttf';
import NunitoBoldItalic from './assets/fonts/Nunito/Nunito-BoldItalic.ttf';

import SignaBold from './assets/fonts/Signa/Signa-Bold.ttf';
import SignaBlackItalic from './assets/fonts/Signa/Signa-BlackItalic.ttf';

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: bold;
    src: url(${ComfortaaBold})
  }

  @font-face {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 600;
    src: url(${ComfortaaSemiBold})
  }

  @font-face {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: medium;
    src: url(${ComfortaaMedium})
  }

  @font-face {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: normal;
    src: url(${ComfortaaRegular})
  }

  @font-face {
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 400;
    src: url(${ComfortaaLight})
  }

  @font-face {
    font-family: "Nunito";
    font-style: extra-light;
    font-weight: 300;
    src: url(${NunitoExtraLight});
  }

  @font-face {
    font-family: "Nunito";
    font-style: light;
    font-weight: 400;
    src: url(${NunitoLight});
  }

  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    src: url(${NunitoRegular});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: normal;
    src: url(${NunitoItalic});
  }

  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    src: url(${NunitoSemiBold});
  }
  @font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: bold;
    src: url(${NunitoBold});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: bold;
    src: url(${NunitoBoldItalic});
  }

  @font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 600;
    src: url(${NunitoSemiBoldItalic});
  }

  @font-face {
    font-family: "Signa";
    font-style: normal;
    font-weight: bold;
    src: url(${SignaBold});
  }

  @font-face {
    font-family: "Signa";
    font-style: italic;
    font-weight: black;
    src: url(${SignaBlackItalic});
  }

`;
