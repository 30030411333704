import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Container = styled.div`
	z-index: 1000003;
`;

export const AnimateOpen = keyframes`
    from{
        opacity: 0;
        top: 0px;
    }
    to{
        opacity: 1;
        top: 20px;
    }
`;

export const Notify = styled.div`
	width: 90vw;
	background: ${defines.BackgroundColor};
	-webkit-box-shadow: 10px 10px 30px 1px rgba(181, 181, 182, 0.4);
	box-shadow: 10px 10px 30px 1px rgba(181, 181, 182, 0.4);
	border-radius: 20px;
	padding: 10px;
	box-sizing: border-box;
	animation-name: ${AnimateOpen};
	animation-duration: 0.3s;
	animation-timing-function: ease-in;
	position: absolute;
	top: 20px;
	left: 5vw;
	display: flex;
	align-items: center;

	z-index: 100000;
`;

export const Circle = styled.div<{ color: string }>`
	width: 28px;
	height: 28px;
	border: 2px solid ${(props) => props.color};
	border-radius: 50%;
	background: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
`;

export const Icon = styled.img`
	height: 16px;
`;

export const Title = styled.p`
	margin: 0;
	font-family: 'Nunito';
	font-weight: 600;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
`;

export const Text = styled.p`
	margin: 0;
	font-family: 'Nunito';
	font-weight: 300;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.8);
`;

export const TextContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: calc(100% - 55px);
`;
