import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { State } from '../../../redux/store';
import LogoConecta from '../../../../assets/images/logoConecta.png';
import BrilhoIcon from '../../../../assets/icons/brilho-icone.png';
import ProgramadoraIcon from '../../../../assets/icons/programadora-icone.png';
import SurpresoIcon from '../../../../assets/icons/surpreso-icone.png';
import ConstrucaoIcon from '../../../../assets/icons/construcao-icone.png';
import PlacaConstrucaoIcon from '../../../../assets/icons/placa-construcao-icone.png';
import DesenvolvimentoIcon from '../../../../assets/icons/desenvolvimento-icone.png';

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
};

const DesktopOnly: React.FC<SelectUnitProps> = ({ toggle }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<Modal toggleModal={toggle}>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gridGap: '30px' }}>
				<Body.Row
					style={{ justifyContent: 'center', textAlign: 'center' }}
				>
					<Body.Title>
						Atenção! Não é possível visualizar e editar planos no celular!
					</Body.Title>
				</Body.Row>
				<Body.Row>
					<Body.FloatIcon1
						style={{ height: '80px', marginRight: '-30px' }}
						src={PlacaConstrucaoIcon}
					/>
					<Body.FloatIcon2
						style={{ height: '80px', marginRight: '-30px' }}
						src={ProgramadoraIcon}
					/>
					<Body.FloatIcon3
						style={{
							height: '60px',
							marginLeft: '-20px',
							marginBottom: '20px',
						}}
						src={BrilhoIcon}
					/>
				</Body.Row>
				<Body.Row
					style={{ justifyContent: 'center', textAlign: 'center' }}
				>
					<Body.Title>
						A página que você está tentando acessar é exclusiva para computadores.
					</Body.Title>
				</Body.Row>
			</div>
		</Modal>
	);
};

export default DesktopOnly;
