import styled, { keyframes } from 'styled-components';
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.img<{ size: number }>`
	height: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
	animation: ${rotate} 0.8s linear infinite;
`;
