import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../../components/Modals/Modal/Modal';
import Input from '../../../components/Inputs/Input/Input';
import * as Body from '../Styles';
import * as Functions from '../../../../utils/functions/Functions';
import * as Defines from '../../../../utils/defines/Defines';
import Button from '../../../components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { State } from '../../../redux/store';

type ChangePWDProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	idUsuario: string;
};

export const ChangePWD: React.FC<ChangePWDProps> = ({ toggle, idUsuario }) => {
	const [loading, toggleLoading] = useState(false);
	const [senha, setSenha] = useState('');
	const userType = useSelector((state: State) => state.userType.userType);
	const [hasLenght, toggleLength] = useState(false);
	const [hasUpper, toggleUpper] = useState(false);
	const [hasLower, toggleLower] = useState(false);
	const [hasSpecial, toggleSpecial] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const tryChange = () => {
		if (!hasLenght || !hasLower || !hasUpper || !hasSpecial) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				text: 'a senha informada não cumpre os requisitos',
				title: '',
				status: 3,
			});
			return;
		}
		toggleLoading(true);
		axios
			.put(
				Defines.apiURL + '/api/UpdateUser?userTypeRequest=' + userType,
				{ senha, idUsuario },
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Senha alterada com sucesso',
				});
				toggle(false);
				toggleLoading(false);
			})
			.catch((error) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 3,
					title: 'Erro',
					text: error.response.data.message,
				});
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggle(false);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		const verify = Functions.checkPasswordRestrictions(senha);

		toggleLength(verify.lenght);
		toggleLower(verify.lowercase);
		toggleUpper(verify.uppercase);
		toggleSpecial(verify.special);
	}, [senha]);

	const getColor = (verify: boolean) => {
		if (verify) {
			return 'green';
		}
		return 'red';
	};

	return (
		<Modal title="Alterar senha" toggleModal={() => toggle(false)}>
			<div
				style={{
					width: '500px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				<Input
					password={true}
					title="Senha"
					text={senha}
					setText={setSenha}
				/>
				<Body.Text style={{ fontWeight: '300' }}>
					Sua senha deve conter letras{' '}
					<strong style={{ color: getColor(hasUpper) }}>
						maiúsculas
					</strong>
					,{' '}
					<strong style={{ color: getColor(hasLower) }}>
						minúsculas
					</strong>
					,{' '}
					<strong style={{ color: getColor(hasSpecial) }}>
						caracteres especiais (ex: @#$)
					</strong>{' '}
					e no minimo
					<strong style={{ color: getColor(hasLenght) }}>
						{' '}
						12 caracteres
					</strong>
				</Body.Text>
				<Button
					text="Alterar"
					action={() => tryChange()}
					loading={loading}
				/>
			</div>
		</Modal>
	);
};

type DeleteAccountProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	idUsuario: string;
};

export const DeleteAccount: React.FC<DeleteAccountProps> = ({
	toggle,
	idUsuario,
}) => {
	const [loading, toggleLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const deleteAccount = () => {
		toggleLoading(true);
		axios
			.delete(
				Defines.apiURL + '/api/UsuarioCRUD?idUsuario=' + idUsuario,
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					text: 'Conta excluida com sucesso',
					title: '',
				});
				toggleLoading(false);
				navigate('/');
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	return (
		<Modal title="Deletar minha conta">
			<div
				style={{
					width: '400px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
					alignItems: 'center',
				}}
			>
				<Body.Text>
					Tem certeza que deseja excluir sua conta? essa ação é
					irreversível
				</Body.Text>
				<Body.Row>
					<Button
						text="Cancelar"
						action={() => toggle(false)}
						width={150}
						loading={loading}
					/>
					<Button
						text="Deletar"
						color={Defines.Red}
						action={() => deleteAccount()}
						width={150}
						disabled={loading}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};
