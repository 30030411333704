// Styles.ts

import styled, { css } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

// Estilos para hover e seleção
const hoveredStyle = css`
	background-color: ${defines.LightBlueTec};
	color: white;
`;

const selectedStyle = css`
	background-color: ${defines.DarkBlueTec};
	color: white;
`;

// Estilos comuns para as células
const commonCellStyles = css<{ isHovered?: boolean; isSelected?: boolean }>`
	width: 125px;
	height: 35px;
	min-width: 125px;
	max-width: 125px;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	text-align: center;
	position: relative;
	cursor: pointer;

	${({ isHovered }) => isHovered && hoveredStyle}
	${({ isSelected }) => isSelected && selectedStyle}
`;

// Título do Plano
export const PlanTitle = styled.th<{
	isHovered?: boolean;
	isSelected?: boolean;
}>`
	${commonCellStyles}
	height: 90px;
	color: white;
	background: ${defines.BlueTec};
	position: sticky;
	top: 0;
	z-index: 4;
`;

// Preço
export const Price = styled.td<{ isHovered?: boolean; isSelected?: boolean }>`
	${commonCellStyles}
	height: 90px;
	color: ${({ isSelected, isHovered }) =>
		isSelected || isHovered ? 'white' : defines.BlueTec};
	background: ${({ isSelected, isHovered }) =>
		isSelected
			? defines.DarkBlueTec
			: isHovered
				? defines.LightBlueTec
				: 'white'};
`;

// Detalhes do Plano
export const PlanDetails = styled.td<{
	isHovered?: boolean;
	isSelected?: boolean;
}>`
	${commonCellStyles}
	color: ${({ isSelected, isHovered }) =>
		isSelected || isHovered ? 'white' : 'black'};
	background: ${({ isSelected, isHovered }) =>
		isSelected
			? defines.DarkBlueTec
			: isHovered
				? defines.LightBlueTec
				: 'white'};
`;

// Opções do Plano
export const PlanOptions = styled.td`
	width: 125px;
	min-width: 125px;
	max-width: 125px;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: black;
	background: transparent;
	text-align: center;
	position: relative;
`;

// Wrapper para os botões de opções
export const PlanOptionsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: opacity 0.2s ease;

	&.show-icons {
		opacity: 1;
	}

	&:hover {
		opacity: 1;
	}
`;

// Botão Selecionar
export const SelectButton = styled.button`
	padding: 5px 10px;
	background-color: green; /* Escolha uma cor adequada */
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-family: 'Nunito';
	font-size: 14px;

	&:hover {
		background-color: ${defines.DarkBlueTec};
	}
`;

// Container da Tabela
export const TableContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
`;

// Cabeçalho Escondido
export const ThHidden = styled.th`
	height: 90px;
	min-height: 90px;
	max-height: 90px;
	width: 190px;
	min-width: 190px;
	max-width: 190px;
	position: sticky;
	left: 0;
	background: white;
	z-index: 3;
	visibility: hidden;
	height: 0;
	padding: 0;
	margin: 0;
	border: none;
`;

// Tabela Fixa para a Primeira Coluna
export const FixedTable = styled.table`
	padding-top: 72px;
	border-spacing: 10px;
	table-layout: fixed;
	position: sticky;
	z-index: 2;
	background: ${defines.BackgroundColor}; /* Fundo para cobrir o conteúdo rolável */
`;

// Tabela Scrollável para as Demais Colunas
export const ScrollableTableWrapper = styled.div`
	overflow-x: auto;
	flex: 1;
`;

export const ScrollableTable = styled.table`
	border-spacing: 10px;
	table-layout: fixed;
`;

// Título Vertical do Plano
export const PlanTitleVertical = styled.td`
	height: 90px;
	width: 125px;
	min-width: 125px;
	max-width: 125px;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: white;
	background: ${defines.BlueTec};
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	text-align: left;
	position: sticky;
	left: 0;
	z-index: 2;
`;

// Detalhes Vertical do Plano
export const PlanDetailsVertical = styled.td`
	width: 125px;
	min-width: 125px;
	max-width: 125px;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: black;
	background: white;
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	text-align: left;
	position: sticky;
	left: 0;
	z-index: 1;
`;

// Cell Selecionado
export const SelectedCell = styled.address`
	font-style: normal;
	font-weight: bold;
	color: ${defines.BlueTec};
	transition: opacity 0.2s ease;
`;

// Adicione este estilo para sincronizar as alturas das linhas
export const TableRow = styled.tr`
	display: flex;
	width: 100%;
`;

// Ajuste as células para usar Flexbox
export const TableCell = styled.td`
	flex: 0 0 125px; /* Defina conforme necessário */
	display: flex;
	align-items: center;
	justify-content: center;
	/* Remova padding e margin se necessário */
	padding: 10px;
	box-sizing: border-box;
`;

// Repita para th e outras células, garantindo consistência
export const TableHeaderCell = styled.th`
	flex: 0 0 125px; /* Defina conforme necessário */
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	box-sizing: border-box;
`;

// CreatedAt
export const CreatedAt = styled.td<{
	isHovered?: boolean;
	isSelected?: boolean;
}>`
	${commonCellStyles}
	color: ${({ isSelected, isHovered }) =>
		isSelected || isHovered ? 'white' : 'gray'};
	background: ${({ isSelected, isHovered }) =>
		isSelected
			? defines.DarkBlueTec
			: isHovered
				? defines.LightBlueTec
				: 'white'};
	font-size: 12px;
`;

export const CreatedAtVertical = styled.td`
	${commonCellStyles}
	color: ${({ isSelected, isHovered }) =>
		isSelected || isHovered ? 'white' : 'gray'};
	background: ${({ isSelected, isHovered }) =>
		isSelected
			? defines.DarkBlueTec
			: isHovered
				? defines.LightBlueTec
				: 'white'};
	font-size: 12px;
	text-align: left;
	cursor: default;
`;
