import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Types from '../../../../utils/types/Types';
import * as Body from '../Styles';
import * as defines from '../../../../utils/defines/Defines';
import * as Functions from '../../../../utils/functions/Functions';
import Button from '../../../components/Buttons/Button/Button';
import { State } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { AcessLevelProps } from '../../../redux/acessLevel/actionsType';
import axios from 'axios';
import { verifyEmail } from '../../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import CheckIcon from '../../../../assets/icons/checkIconMaterial.svg';
import CloseIcon from '../../../../assets/icons/closeIconMaterial.svg';
import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG/Button';
import SmallTable from '../../../components/Table/SmallTable';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import Table from '../../../components/Table/Table';

type AssinaturaProps = {
	idFilial: string;
	idAssinatura: string;
	idPlano: string;
	dataInicial: string;
	dataVencimento: string;
	nomePlano: string;
	quotaUsuarios: number;
	quotaAtuadorSensor: number;
	quotaEnvio: number;
	preco: number;
	usuarios: {
		linkedUsersList: {
			idUsuario: string;
			nomeCompleto: string;
			email: string;
			visitante?: number;
			cargo: number;
		}[];
		visitingUsersList: {
			idUsuario: string;
			nomeCompleto: string;
			email: string;
			visitante?: number;
			cargo: number;
		}[];
		availableUsersList: {
			idUsuario: string;
			nomeCompleto: string;
			email: string;
			visitante?: number;
			cargo: number;
		}[];
	};
};

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

interface BasePlanoProps {
	idPlanoInSight?: string;
	idPlanosDHM?: string;
	idPlanoRelatorios?: string;
	nomePlano: string;
	preco: number;
	createdAt: string;
}

type PlanoProps = BasePlanoProps & {
	[key: string]: any;
	[key: number]: any;
};

type CreateSubscriptionProps = {
	setStep: Dispatch<SetStateAction<number>>;
	toggleLoading: Dispatch<SetStateAction<boolean>>;
	app: string;
};

const CreateSubscription: React.FC<CreateSubscriptionProps> = ({
	setStep,
	toggleLoading,
	app,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			toggleLoading(false);
		}, 1000);
	}, [navigate, dispatch]);

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	return (
		<>
			<br />
			<Body.Row
				style={{ justifyContent: 'center', alignItems: 'center' }}
			>
				<Body.IconWrapper hasSubscription={false}>
					<Body.Icon src={CloseIcon} />
				</Body.IconWrapper>
			</Body.Row>
			<br />
			<Body.Row>
				<Body.Text>
					Essa filial ainda não possui nenhuma assinatura ativa.
				</Body.Text>
			</Body.Row>
			<Body.Row>
				<Body.NoteText>
					É necessário acessar o Conecta no computador para criar uma nova assinatura.
				</Body.NoteText>
			</Body.Row>
		</>
	);
};

type SubscriptionDetailsProps = {
	setStep: Dispatch<SetStateAction<number>>;
	assinatura: AssinaturaProps | null;
	regenerate: () => void;
	toggleLoading: Dispatch<SetStateAction<boolean>>;
	idFilial: string;
	regenerateAssinatura: () => void;
	toggle: (state: boolean) => void;
	app: string;
};

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
	setStep,
	assinatura,
	regenerate,
	toggleLoading,
	idFilial,
	regenerateAssinatura,
	toggle,
	app,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [dataInicioUTC, setDataInicioUTC] = useState(
		assinatura
			? assinatura.dataInicial
			: new Date().toISOString().split('T')[0]
	);
	const [dataVencimentoUTC, setDataVencimentoUTC] = useState(
		assinatura
			? assinatura.dataVencimento
			: new Date().toISOString().split('T')[0]
	);
	const [plano, setPlano] = useState<PlanoProps>();
	const [planoOriginal, setPlanoOriginal] = useState<PlanoProps>();
	const [planosList, setPlanosList] = useState<PlanoProps[]>([]);
	const [changingPlan, setChangingPlan] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(false);

	const handleToggle = () => {
		setDataInicioUTC(
			assinatura
				? assinatura.dataInicial
				: new Date().toISOString().split('T')[0]
		);
		setDataVencimentoUTC(
			assinatura
				? assinatura.dataVencimento
				: new Date().toISOString().split('T')[0]
		);
		setPlano(undefined);
		setPlanoOriginal(undefined);
		setPlanosList([]);
		setChangingPlan(false);
		setUnsavedChanges(false);
		toggle(false);
	};

	useEffect(() => {
		const planoRequest = axios
			.get(
				`${defines.apiURL}/api/PlanosCRUD?INS=1&userTypeRequest=${userType}&api=${app}`,
				{ withCredentials: true }
			)
			.then((response) => {
				setPlanosList(response.data.content);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content[0]
				);
			});

		if (!assinatura) {
			setChangingPlan(true);
			toggleLoading(false);
			return;
		}

		const planosRequest = axios
			.get(
				`${defines.apiURL}/api/PlanosCRUD?INS=1&id=${Functions.ReplaceSpecialCharacters(assinatura.idPlano)}&userTypeRequest=${userType}&api=${app}`,
				{ withCredentials: true }
			)
			.then((response) => {
				setPlano(response.data.content[0]);
				setPlanoOriginal(response.data.content[0]);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content[0]
				);
			});
	}, [navigate, dispatch]);

	const selectPlan = (plan: PlanoProps) => {
		const planId =
			plan.idPlanoInSight || plan.idPlanosDHM || plan.idPlanoRelatorios;
		const currentPlanId =
			plano?.idPlanoInSight ||
			plano?.idPlanosDHM ||
			plano?.idPlanoRelatorios;
		const originalPlanId =
			planoOriginal?.idPlanoInSight ||
			planoOriginal?.idPlanosDHM ||
			planoOriginal?.idPlanoRelatorios;

		if (!plano || currentPlanId !== planId) {
			setPlano(plan);
			setUnsavedChanges(true);
		}
		if (originalPlanId === planId) {
			setUnsavedChanges(false);
		}
		setChangingPlan(false);
	};

	const saveChanges = () => {
		if (new Date(dataInicioUTC) <= new Date(dataInicioUTC)) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: 'Conflito de Datas',
				text: 'A data de vencimento não pode ser anterior a data inicial.',
			});
			return;
		}

		if (!assinatura) {
			axios
				.post(
					`${defines.apiURL}/api/AssinaturaCRUD?idFilial=` +
						Functions.ReplaceSpecialCharacters(idFilial) +
						'&userTypeRequest=' +
						userType,
					{
						idPlano:
							plano?.idPlanoInSight ||
							plano?.idPlanosDHM ||
							plano?.idPlanoRelatorios,
						dataVencimento: dataVencimentoUTC,
						dataInicial: dataInicioUTC,
						api: app,
					},
					{ withCredentials: true }
				)
				.then((response) => {
					regenerate();
					setUnsavedChanges(false);
					regenerateAssinatura();
					handleToggle();
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 1,
						title: 'Assinatura criada',
						text: 'Um plano foi atribuido com sucesso para a filial.',
					});
				})
				.catch((error) => {
					Functions.ResolveErrors(
						error.response.status,
						error.response.data.code,
						error.response.data.message,
						navigate,
						dispatch,
						error.response.data.info
					);
				});
		}
		if (assinatura) {
			axios
				.put(
					`${defines.apiURL}/api/AssinaturaCRUD?idFilial=` +
						Functions.ReplaceSpecialCharacters(idFilial) +
						'&userTypeRequest=' +
						userType +
						'&api=' +
						app,
					{
						idAssinatura: assinatura.idAssinatura,
						idPlano:
							plano?.idPlanoInSight ||
							plano?.idPlanosDHM ||
							plano?.idPlanoRelatorios,
						dataVencimento: dataVencimentoUTC,
						dataInicial: dataInicioUTC,
					},
					{ withCredentials: true }
				)
				.then((response) => {
					regenerate();
					setUnsavedChanges(false);
					regenerateAssinatura();
					handleToggle();
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 1,
						title: 'Assinatura atualizada',
						text: 'O plano da assinatura foi atualizado com sucesso.',
					});
				})
				.catch((error) => {
					Functions.ResolveErrors(
						error.response.status,
						error.response.data.code,
						error.response.data.message,
						navigate,
						dispatch,
						error.response.data.info
					);
				});
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Body.Scroll>

				{changingPlan ? (
					<>
						<Body.Row>
							<Body.Title>
								Selecione um plano para a assinatura.
							</Body.Title>
						</Body.Row>
						<Body.Row>
							<Body.NoteText>
								Você poderá editar as datas após selecionar o plano.
							</Body.NoteText>
						</Body.Row>
						<Table
							content={planosList}
							contentMap={
								app === 'INS'
									? {
											idPlanoInSight: 'ID',
											nomePlano: 'Nome',
											quotaUsuarios: 'Usuários permitidos',
											quotaAtuadorSensor:
												'Sensores e atuadores',
											quotaEnvio: 'Requisições mensais',
											preco: 'Quotas',
										}
									: {
											idPlanosDHM: 'ID',
											nomePlano: 'Nome',
											quotaUsuarios: 'Usuários permitidos',
											preco: 'Preço',
										}
							}
							editPlan={() => {}}
							deletePlan={() => {}}
							dynamicDescriptions={
								app === 'INS'
									? [
											'quotaUsuarios',
											'quotaAtuadorSensor',
											'quotaEnvio',
										]
									: ['quotaUsuarios']
							}
							selectable
							selectAction={selectPlan}
							preSelected={
								app === 'INS'
									? plano?.idPlanoInSight
									: plano?.idPlanosDHM
							}
						/>
						<br />
					</>
				) : plano ? (
					<SmallTable
						content={plano}
						contentMap={
							app === 'INS'
								? {
										idPlanoInSight: 'ID',
										nomePlano: 'Nome',
										quotaUsuarios: 'Usuários permitidos',
										quotaAtuadorSensor: 'Sensores e atuadores',
										quotaEnvio: 'Requisições mensais',
										preco: 'Quotas',
									}
								: {
										idPlanosDHM: 'ID',
										nomePlano: 'Nome',
										quotaUsuarios: 'Usuários permitidos',
										preco: 'Preço',
									}
						}
						dynamicDescriptions={
							app === 'INS'
								? [
										'quotaUsuarios',
										'quotaAtuadorSensor',
										'quotaEnvio',
									]
								: ['quotaUsuarios']
						}
					/>
				) : null}

				<Body.Row>
					<DatetimeInput
						title="Data de Início"
						text={dataInicioUTC}
						setText={setDataInicioUTC}
						removeHours
						disabled
					/>
					<DatetimeInput
						title="Data de Vencimento"
						text={dataVencimentoUTC}
						setText={setDataVencimentoUTC}
						removeHours
						disabled
					/>
				</Body.Row>

				<div style={{marginBottom: '20px'}} />

				<Body.TextWarning style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Nunito', marginTop: '10px' }}>
					Alterações da assinatura só podem ser realizadas no computador!
				</Body.TextWarning>

				<div style={{marginBottom: '60px'}} />

			</Body.Scroll>
		</div>
	);
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	regenerateAssinatura: () => void;
	idFilial: string;
	app: string;
};

const EditSubscription: React.FC<SelectUnitProps> = ({
	toggle,
	regenerateAssinatura,
	idFilial,
	app,
}) => {
	const [loading, toggleLoading] = useState(false);
	const userType = useSelector((state: State) => state.userType.userType);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [assinatura, setAssinatura] = useState<AssinaturaProps>();
	const [step, setStep] = useState(-1);

	const handleToggle = (state: boolean) => {
		setAssinatura(undefined);
		setStep(-1);
		toggle(state);
	};

	useEffect(() => {
		setTimeout(() => {
			toggleLoading(false);
		}, 1000);
		axios
			.get(
				`${defines.apiURL}/api/AssinaturaCRUD?subscriptionByBranch=1&api=${app}&idFilial=${Functions.ReplaceSpecialCharacters(idFilial)}&userTypeRequest=${userType}`,
				{ withCredentials: true }
			)
			.then((response) => {
				const assinaturaData: AssinaturaProps =
					response.data.content[0];
				setAssinatura(assinaturaData);

				if (response.data.content[0]) {
					setStep(1);
				} else {
					setStep(0);
				}
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content[0]
				);
			});
	}, [navigate, dispatch]);

	const regenerate = () => {
		toggleLoading(true);
		axios
			.get(
				`${defines.apiURL}/api/AssinaturaCRUD?subscriptionByBranch=1&api=${app}&idFilial=${Functions.ReplaceSpecialCharacters(idFilial)}&userTypeRequest=${userType}`,
				{ withCredentials: true }
			)
			.then((response) => {
				const assinaturaData: AssinaturaProps =
					response.data.content[0];
				setAssinatura(assinaturaData);
				setStep(1);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content[0]
				);
			});
	};

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	const getContent = () => {
		if (step === -1) return <></>;
		if (step === 0)
			return (
				<CreateSubscription
					setStep={setStep}
					toggleLoading={toggleLoading}
					app={app}
				/>
			);
		if (step === 1)
			return (
				<SubscriptionDetails
					setStep={setStep}
					assinatura={assinatura || null}
					regenerate={regenerate}
					toggleLoading={toggleLoading}
					idFilial={idFilial}
					regenerateAssinatura={regenerateAssinatura}
					toggle={handleToggle}
					app={app}
				/>
			);
	};

	return (
		<Modal
			title={
				(assinatura ? 'Visualizar Assinatura' : 'Criar Assinatura') +
				' - ' +
				getAplicativo(app)
			}
			toggleModal={() => toggle(false)}
			loading={loading}
		>
			{getContent()}
		</Modal>
	);
};

export default EditSubscription;
