import styled, { keyframes } from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

export const Button = styled.a<{ active?: boolean }>`
	cursor: pointer;
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.img`
	height: 25px;
	width: 25px;
	animation: ${rotate} 0.8s linear infinite;
`;
