import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import * as Types from '../../../utils/types/Types';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Avatar from '../../../assets/images/avatar.png';
import VisitaIcon from '../../../assets/icons/visita-icon.svg';
import UsuarioIcon from '../../../assets/icons/usuario-icon.svg';
import TecnicoIcon from '../../../assets/icons/tecnico-icon.svg';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';
import { ConfirmDeleteCompany } from './Modals/ConfirmDeleteCompany';

const EditCompany = () => {
	const { state } = useLocation();
	const company = state as Types.EmpresaInfo;

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<Types.EmpresaInfo[]>([]);
	const [loading, toggleLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [idEmpresa, setId] = useState(company.idEmpresa);
	const [nomeEmpresa, setNomeEmpresa] = useState(company.nomeEmpresa);
	const [cnpj, setCNPJ] = useState(company.cnpj);


	const updateCompany = () => {
		if (nomeEmpresa.length === 0 || cnpj.length === 0) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: '',
				text: 'Preencha todos os campos',
			});
			return;
		}
		axios
			.put(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ idEmpresa, nomeEmpresa, cnpjEmpresa: cnpj },
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Dados da empresa alterados com sucesso',
				});
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};
	
	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
			/>
			<ButtonWrapper
				action={() => {
					navigate('/empresas');
				}}
				style={{
					position: 'absolute',
					top: '20px',
					left: '20px',
					minWidth: '50px',
					minHeight: '50px',
					backgroundColor: 'white',
					borderRadius: '50%',
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: 1,
					boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
				}}
			>
				<Body.CardIcon src={ArrowBack} />
			</ButtonWrapper>

			<div style={{ margin: '10px' }} />

			<Body.Row>
				<Input
					text={nomeEmpresa}
					setText={setNomeEmpresa}
					placeholder="Nome da Empresa"
					title="Nome da Empresa"
				/>
			</Body.Row>
			<Body.Row>
				<Input
					text={cnpj}
					setText={setCNPJ}
					placeholder="CNPJ da Empresa"
					title="CNPJ da Empresa"
					disabled
				/>
			</Body.Row>

			<Button text="Visualizar Filiais" color="yellow" action={() => {
				navigate('/filiais', { state: company });
			}} />

			<Button text="Salvar" color="blue" action={() => {updateCompany()}} 
				disabled={!(acessLevel === defines.CargoTectrol['Suporte'] || acessLevel === defines.CargoTectrol['Engenheiro'])}/>

			<Button
				text="Excluir Empresa"
				color="red"
				action={() => {toggleDelete(true)}}
				disabled={!(acessLevel === defines.CargoTectrol['Suporte'] || acessLevel === defines.CargoTectrol['Engenheiro'])}
			/>

			{loading && <Loading />}
			{modalDelete ? (
				<ConfirmDeleteCompany
					nome={nomeEmpresa}
					id={idEmpresa}
					toggle={toggleDelete}
					action={() => {navigate('/empresas');}}
				/>
			) : null}
		</Body.Container>
	);
};

export default EditCompany;
