export const BackgroundColor = '#F8F8F9';
export const GreenTec = '#4CB931';
export const BlueTec = '#1080D6';
export const DarkBlueTec = '#0D64A8';
export const LightBlueTec = '#5A9FD3';
export const Yellow = '#E79720';
export const Red = '#F34A4A';

export const TypeSidebar = {
	DEFAULT_USER: 1,
	VISITING_USER: 2,
	TECTROL_USER: 3,
};

export const ContentSidebar = {
	MY_ACCOUNT: 1,
	CONNECTED_APPS: 2,
	MANAGE_USERS: 3,
	MANAGE_PERMISSIONS: 4,
	MANAGE_COMPANY: 5,
	INVITES: 6,
	APPS_AND_PLANS: 7,
};

export const NivelAcesso = {
	TECNICO: 1,
	ADMINISTRADOR: 2,
	TECTROL: 3,
};

export const DHMAcesso = {
	TECNICO: 1,
	SUPERVISOR: 2,
	ADMINISTRADOR: 3,
	TECTROL: 4,
};

export const InSightAcesso = {
	TECNICO: 1,
	SUPERVISOR: 2,
	ADMINISTRADOR: 3,
	TECTROL: 4,
};

export const CargoTectrol = {
	Comercial: 1,
	Engenheiro: 2,
	Suporte: 3,
	Padrão: 4,
};

export const CargoVisitante = {
	'Técnico Visitante': 1,
	'Suporte Visitante': 2,
};

export const CargoEmpresarial = {
	Padrão: 1,
	Administrador: 2,
};

export const CargoApi = {
	Técnico: 1,
	Supervisor: 2,
	Administrador: 3,
};

export const Apis = {
	'DHM4.0': 1,
	IndustrySight: 2,
	Relatórios: 3,
};


export const apiURL = "https://www.conecta.tectrolnet.com.br"
// export const apiURL = "http://localhost:7071"
// export const apiURL = "https://pink-racoon-1927h10.azurewebsites.net"
