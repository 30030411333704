import styled, { keyframes } from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

export const Button = styled.a<{
	size?: number;
	active?: boolean;
	color?: string;
	hoverColor?: string;
}>`
	cursor: pointer;
	background-color: ${(props) => (props.color ? props.color : 'transparent')};
	padding: 10px;
	border-radius: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		color: ${(props) =>
			props.hoverColor
				? props.hoverColor
				: props.color
					? props.color
					: defines.BlueTec};
	}
`;

export const Icon = styled.img`
	height: 25px;
	width: 25px;
`;
