import styled, { keyframes } from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

export const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(4px);
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 100;
`;

export const AnimateOpen = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;

export const Modal = styled.div`
	min-height: 100px;
	max-height: 80vh;
	width: 90vw;
	background: ${defines.BackgroundColor};
	border-radius: 20px;
	padding: 30px;
	box-sizing: border-box;
	animation-name: ${AnimateOpen};
	animation-duration: 0.3s;
	animation-timing-function: ease-in;
	// -webkit-box-shadow: 10px 10px 30px 1px rgba(211,211,212,0.4);
	// box-shadow:         10px 10px 30px 1px rgba(211,211,212,0.4);
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	grid-gap: 0px 30px;
`;

export const TitleContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.p`
	margin: 0;
	font-family: 'Nunito';
	font-size: 18px;
	color: #000;
	font-weight: normal;
`;
export const SubTitle = styled.p`
	margin: 0;
	font-family: 'Nunito';
	font-size: 14px;
	color: #000;
	opacity: 0.8;
`;

export const Content = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none; 
    }
    height: 100%;
    display: flex;
    flex-direction: column;F
    align-items: center;
`;
export const BtnIcon = styled.img`
	height: 15px;
	width: 15px;
	min-width: 15px;
	min-height: 15px;
`;

const getColor = (value: string) => {
	switch (value) {
		case 'blue':
			return defines.BlueTec;
		case 'red':
			return defines.Red;
		case 'yellow':
			return defines.Yellow;
		case 'green':
			return defines.GreenTec;
		default:
			return defines.BlueTec;
	}
};

export const Button = styled.button<{ color?: string }>`
	height: 30px;
	width: 30px;
	background: ${(props) =>
		props.color ? getColor(props.color) : defines.BlueTec};
	border: solid 1px
		${(props) => (props.color ? getColor(props.color) : defines.BlueTec)};
	transition: 0.3s;
	color: #fff;
	box-sizing: border-box;
	border-radius: 25px;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	position: relative;
	display: flex;
	grid-gap: 0px 15px;

	:hover {
		transition: 0.3s;
		opacity: 0.8;
	}
`;
export const Row = styled.div`
	display: flex;
	grid-gap: 0px 5px;
`;
