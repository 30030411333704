import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Avatar from '../../../assets/images/avatar.png';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import { State } from '../../redux/store';
import SendInvite from './Modals/SendInvite';

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	fotoUsuario: string | null;
	tipoTectrol: number;
};

const TectrolUserList = () => {
	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([]);
	const [data, setData] = useState<UsuarioProps[]>([]);
	const [loading, toggleLoading] = useState(true);
	const [createUser, toggleCreateUser] = useState(false);

	const navigate = useNavigate();
	const inputRef = useRef<HTMLInputElement>(null);

	const getUsers = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL + '/api/TectrolCRUD?userTypeRequest=' + userType,
				{
					withCredentials: true,
				}
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeCompleto'
				);
				setData(sorted);
				setFiltered(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					undefined,
					error.response.data.content
				);
			});
	};

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFiltered(data);
			return;
		}
		const filtered = data.filter((item) =>
			item.nomeCompleto.toUpperCase().includes(name.toUpperCase())
		);
		setFiltered(filtered);
	};

	const handleUserClick = (user: UsuarioProps) => {
		navigate('/tectrol/editar', { state: user });
	};

	const getCargo = (id: number) => {
		switch (id) {
			case defines.CargoTectrol['Comercial']:
				return 'Comercial';
			case defines.CargoTectrol['Engenheiro']:
				return 'Engenharia';
			case defines.CargoTectrol['Suporte']:
				return 'Suporte';
			case defines.CargoTectrol['Padrão']:
				return 'Padrão';
			default:
				return 'Cargo Indisponível';
		}
	};

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_USERS}
			/>

			<Body.PageTitle>Usuários Internos</Body.PageTitle>
			<Body.SubTitle>
				Toque em um usuário para visualizar seus detalhes.
			</Body.SubTitle>
			<Body.Row
				style={{
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: '20px',
				}}
			>
				<Body.FindContent>
					<Body.Find
						placeholder="Buscar usuário"
						onChange={(e) => filterData(e.target.value)}
						ref={inputRef}
					/>
					<Body.Icon
						src={SearchIcon}
						style={{ cursor: 'text' }}
						onClick={() => inputRef.current?.focus()}
					/>
				</Body.FindContent>
				{acessLevel === defines.CargoTectrol['Suporte'] && (
					<Body.HeaderButton onClick={() => toggleCreateUser(true)}>
						<Body.Icon
							src={AddIcon}
							style={{ position: 'relative', top: 0, left: 0 }}
						/>
					</Body.HeaderButton>
				)}
			</Body.Row>
			<Body.Scroll style={{ marginTop: '20px' }}>
				{filteredUsers.length === 0 ? (
					<Body.Text>Nenhum usuário encontrado</Body.Text>
				) : (
					filteredUsers.map((item, index) => (
						<Body.SelectUser
							onClick={() => handleUserClick(item)}
							key={index.toString()}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<Body.Photo src={item.fotoUsuario || Avatar} />
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Body.Text>{item.nomeCompleto}</Body.Text>
									<Body.NoteText>
										{getCargo(item.tipoTectrol)}
									</Body.NoteText>
								</div>
							</div>
						</Body.SelectUser>
					))
				)}
			</Body.Scroll>

			{createUser ? <SendInvite toggle={toggleCreateUser} /> : null}
			{loading && <Loading />}
		</Body.Container>
	);
};

export default TectrolUserList;
