import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const AnimateOpen = keyframes`
    from{
        right: -200px;
    }
    to{
        right: 0px;
    }
`;

export const Card = styled.div`
	width: 100vw;
	height: 100vh;
	border-radius: 20px;
	border: none;
	left: 0px;
	top: 0px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	animation-name: ${AnimateOpen};
	animation-duration: 0.1s;
	animation-timing-function: ease-in;

	background-color: ${defines.BackgroundColor};
	-webkit-box-shadow:
		10px 10px 30px 1px rgba(211, 211, 212, 0.4),
		-10px -10px 30px 1px rgba(255, 255, 255, 0.8);
	box-shadow:
		-10px -10px 30px 1px rgba(255, 255, 255, 0.8),
		10px 10px 30px 1px rgba(211, 211, 212, 0.4);
	position: absolute;
	z-index: 10;
`;

export const Photo = styled.img`
	width: 180px;
	height: 180px;
	border-radius: 90px;
`;
export const Title = styled.a`
	font-family: 'Comfortaa';
	font-weight: medium;
	font-size: 20px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 10px 0px;
`;

export const Image = styled.img`
	width: 100px;
	height: 70px;
`;

export const BottomBar = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgba(10, 10, 10, 0.4);
`;

export const StyledBurger = styled.button<{ open: boolean }>`
	position: absolute;
	top: 20px;
	right: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 11;
	border-radius: 50%;
	background-color: white;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
	width: 50px;
	height: 50px;

	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.25rem;
		background: #000;
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		left: ${({ open }) => (!open ? '10px' : '8px')};

		:first-child {
			top: ${({ open }) => (!open ? '8px' : '0px')};
			transform: ${({ open }) => (open ? 'rotate(45deg) translateX(9px)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) =>
				open ? 'translateX(6px) translateY(-12px) rotate(45deg)' : 'translateX(0) translateY(0) rotate(0deg)'};
		}

		:nth-child(3) {
			top: ${({ open }) => (!open ? '-8px' : '0px')};
			transform: ${({ open }) => (open ? 'rotate(-45deg) translateX(9px)' : 'rotate(0)')};
		}
	}
`;

export const Subtitle = styled.a`
    font-family: "Comfortaa";
    font-weight: medium;
    font-size: 12px;
    margin-top: -8px;
    color: #6D7989;
`;

export const UnityBadge = styled.div`
	font-family: 'Comfortaa';
	font-size: 12px;
	padding: 4px;
	padding-left: 12px;
	padding-right: 12px;
	color: white;
	background-color: #6d7989;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BadgeIcon = styled.img`
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	max-width: 16px;
	max-height: 16px;
`;
