import React from 'react';
import * as Body from './Styles';
import Spinner from '../../../../assets/icons/spinner-blue.svg';

type ButtonProps = {
	action: any;
	text: string;
	size?: number;
	active?: boolean;
	loading?: boolean;
	color?: string;
	hoverColor?: string;
};

const ButtonWithoutBG: React.FC<ButtonProps> = ({
	action,
	text,
	size,
	active,
	loading,
	color,
	hoverColor,
}) => {
	return loading ? (
		<Body.Icon src={Spinner} />
	) : (
		<Body.Button
			onClick={action}
			size={size}
			active={active}
			color={color}
			hoverColor={hoverColor}
		>
			{text}
		</Body.Button>
	);
};

export default ButtonWithoutBG;
