import * as Actions from './actionsType';

const ReqTFA = (state: boolean = false, actions: Actions.TFAProps) => {
	switch (actions.type) {
		case Actions.REQ_TFA:
			state = true;
			return state;
		case Actions.REMOVE_TFA:
			state = false;
			return state;
	}
	return state;
};

export default ReqTFA;
