import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import store, { persistor, State } from '../redux/store'; // importar também o `persistor`
import { PersistGate } from 'redux-persist/integration/react'; // importar o `PersistGate`

import Login from '../pages/login/Login';
import Recover from '../pages/recover/Recover';
import MyAccount from '../pages/myAccount/myAccount';
import ConnectedApps from '../pages/connectedApps/connectedApps';
import ManageUsers from '../pages/manageUsers/manageUsers';
import Notify from '../components/Notify/Notify';
import ActivateAccount from '../pages/activateAccount/ActivateAccount';
import Privacy from '../pages/privacy/Privacy';
import Alert from '../components/Alert/Alert';
import ManageCompanies from '../pages/manageCompanies/ManageCompanies';
import PrivacyCompany from '../pages/privacyCompany/PrivacyCompany';
import AccessMenu from '../pages/accessMenu/accessMenu';
import CreateVisitingUser from '../pages/createUser/createUser';
import VisitingConnectedApps from '../pages/visitingConnectedApps/visitingConnectedApps';
import VisitingInvites from '../pages/visitingInvites/visitingInvites';
import AppsPlans from '../pages/appsPlans/appsPlans';
import ManageSubscription from '../pages/manageSubscription/manageSubscription';
import ManagePlans from '../pages/managePlans/managePlans';
import ManageUnities from '../pages/manageUnities/ManageUnities';
import ManageTectrolUsers from '../pages/manageTectrolUsers/manageTectrolUsers';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Authentication from '../pages/authentication/Authentication';
import SelectUnity from '../components/Modals/SelectUnity/SelectUnity';
import DefaultManageUsers from '../pages/defaultManageUsers/defaultManageUsers';
import PageNotFound from '../pages/PageNotFound/pageNotFound';
import DefaultManageSubscription from '../pages/defaultManageSubscription/defaultManageSubscription';

const RoutesDesktop = () => {
	return (
		<BrowserRouter>
			<Provider store={store}>
				{/* PersistGate vai garantir que o estado seja restaurado antes da renderização */}
				<PersistGate loading={null} persistor={persistor}>
					<Routes>
						{/* Login */}
						<Route path="/" element={<Login />} />
						<Route path="/menu" element={<AccessMenu />} />
						<Route
							path="/menu/vincular-filial"
							element={<AccessMenu fromEmailInvite />}
						/>

						{/* Páginas Intermediárias */}
						<Route path="/recuperar" element={<Recover />} />
						<Route
							path="/ativar-conta"
							element={<ActivateAccount />}
						/>
						<Route path="/privacidade" element={<Privacy />} />
						<Route
							path="/privacidade-empresa"
							element={<PrivacyCompany />}
						/>
						<Route
							path="/cadastro"
							element={<CreateVisitingUser />}
						/>

						{/* Páginas em comum */}
						<Route path="/minha-conta" element={<MyAccount />} />

						{/* Usuário Visitante */}
						<Route
							path="/apps-conectados-visitante"
							element={<VisitingConnectedApps />}
						/>
						<Route
							path="/convites-visitante"
							element={<VisitingInvites />}
						/>

						{/* Usuário Tectrol */}
						<Route path="/aplicativos" element={<AppsPlans />} />
						<Route
							path="/aplicativos/planos"
							element={<ManagePlans />}
						/>
						<Route
							path="/aplicativos/assinaturas"
							element={<ManageSubscription />}
						/>

						<Route
							path="/tectrol"
							element={<ManageTectrolUsers />}
						/>

						<Route path="/empresas" element={<ManageCompanies />} />
						<Route
							path="/empresas/filiais"
							element={<ManageUnities />}
						/>
						<Route
							path="/empresas/filiais/usuarios"
							element={<ManageUsers />}
						/>

						{/* Usuário Empresarial */}
						<Route
							path="/apps-conectados"
							element={<ConnectedApps />}
						/>
						<Route
							path="/apps-conectados/permissoes"
							element={<DefaultManageSubscription />}
						/>
						<Route
							path="/empresarial"
							element={<DefaultManageUsers />}
						/>

						{/* Página não encontrada */}
						<Route path="*" element={<PageNotFound notFound />} />
						<Route
							path="/permissao"
							element={<PageNotFound noPermission />}
						/>
					</Routes>
					<Notify />
					<Alert />
					<Authentication />
					<SelectUnity />
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
};

export default RoutesDesktop;
