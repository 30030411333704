import { combineReducers } from 'redux';
import notifications from './notifications/reducer';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { configureStore } from '@reduxjs/toolkit';
import acessLevel from './acessLevel/reducer';
import alert from './alert/reducer';
import userType from './userType/reducer';
import selectedUnity from './selectedUnity/reducer';
import ReqTFA from './TFA/reducer';
import ReqUnity from './toggleUnity/reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['acessLevel', 'userType', 'selectedUnity'],
};

const rootReducer = combineReducers({
	ReqTFA: ReqTFA,
	ReqUnity: ReqUnity,
	notifications: notifications,
	acessLevel: acessLevel,
	userType: userType,
	selectedUnity: selectedUnity,
	alert: alert,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;

export type State = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
