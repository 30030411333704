import axios from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Buttons/Button/Button';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import * as Types from '../../../../utils/types/Types';
import * as Functions from '../../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import { apiURL } from '../../../../utils/defines/Defines';
import SearchIcon from '../../../../assets/icons/Search.svg';
import { State } from '../../../redux/store';
import userType from '../../../redux/userType/reducer';

type AddCompanyProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	getData: any;
};

export const AddCompany: React.FC<AddCompanyProps> = ({ toggle, getData }) => {
	const userType = useSelector((state: State) => state.userType.userType);
	const [nomeEmpresa, setEmpresa] = useState('');
	const [cnpj, setCnpj] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, toggleLoading] = useState(false);

	const formatCnpj = (value: string) => {
		return value
			.replace(/[^0-9a-zA-Z]/g, '') // Remove all non-alphanumeric characters
			.replace(/^(\w{2})(\w)/, '$1.$2') // Add dot after first 2 characters
			.replace(/^(\w{2})\.(\w{3})(\w)/, '$1.$2.$3') // Add dot after next 3 characters
			.replace(/\.(\w{3})(\w)/, '.$1/$2') // Add slash after next 3 characters
			.replace(/(\w{4})(\w)/, '$1-$2') // Add dash after next 4 characters
			.replace(/(-\w{2})\w+?$/, '$1'); // Prevent more than 14 characters
	};

	const handleCnpjChange = (text: string) => {
		setCnpj(formatCnpj(text));
	};

	const tryCreate = () => {
		toggleLoading(true);
		axios
			.post(
				apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ cnpj, nomeEmpresa },
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Empresa cadastrada com sucesso',
				});
				getData();
				toggleLoading(false);
				toggle(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.content || error.response.data.message,
					navigate,
					dispatch
				);
				toggleLoading(false);
			});
	};

	return (
		<Modal toggleModal={toggle} title="Cadastrar empresa">
			<>
				<Body.Scroll
					style={{
						gridGap: '10px 0px',
						marginBottom: '10px',
					}}
				>
					<Input
						text={nomeEmpresa}
						setText={setEmpresa}
						title="Nome"
					/>
					<div style={{ height: '10px' }} />
					<Input
						text={cnpj}
						setText={handleCnpjChange}
						title="CNPJ"
					/>
					<Body.Text style={{ color: 'gray', fontSize: '12px' }}>
						O CNPJ deverá estar no formato 12.345.678/0001-90
					</Body.Text>
					<div style={{ height: '10px' }} />
				</Body.Scroll>
				<Button
					text="Criar"
					action={() => tryCreate()}
					loading={loading}
				/>
			</>
		</Modal>
	);
};

type ConfirmDeleteProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	id: string;
	nome: string;
	action: any;
};

export const ConfirmDeleteCompany: React.FC<ConfirmDeleteProps> = ({
	toggle,
	id,
	action,
	nome,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);
	const [loading, toggleLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const tryDelete = () => {
		toggleLoading(true);
		axios
			.delete(
				apiURL +
					'/api/EmpresaCRUD?userTypeRequest=' +
					userType +
					'&idEmpresa=' +
					Functions.ReplaceSpecialCharacters(id),
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Empresa excluida com sucesso',
				});
				toggleLoading(false);
				action();
				toggle(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	return (
		<Modal title="Excluir Empresa">
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Body.Text style={{ marginBottom: '20px', fontWeight: 400 }}>
					Tem certeza que deseja excluir a empresa{' '}
					<strong>{nome}</strong>?
					<br /> Essa ação não poderá ser desfeita.
				</Body.Text>
				<Body.Row>
					<Button
						text="Excluir"
						width={200}
						color="red"
						action={() => tryDelete()}
						loading={loading}
					/>
					<Button
						text="Cancelar"
						width={200}
						action={() => toggle(false)}
						disabled={loading}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};
