import React, { useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import Button from '../../components/Buttons/Button/Button';
import CheckIcon from '../../../assets/icons/check-icon-white.svg';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import LogoConecta from '../../../assets/images/logoConecta.png';
import { State } from '../../redux/store';

type Props = {
	nome?: string;
	filial?: string;
	action: any;
};

const DPO: React.FC<Props> = ({ nome, filial, action }) => {
	const [accept, toggleAccept] = useState(false);

	const openLink = (link: string) => {
		window.open(link, '_blank');
	};

	return (
		<Body.Content>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginBottom: 35,
					justifyContent: 'space-between',
				}}
			>
				<Body.SubTitle style={{ fontWeight: 300, marginBottom: 0 }}>
					Termo de Compartilhamento de Dados
				</Body.SubTitle>
			</div>
			<Body.Text style={{ marginBottom: 5 }}>
				Para cumprir com a{' '}
				<strong>Lei Geral de Proteção de Dados</strong>, solicitamos que
				nossos clientes aceitem nosso
				<strong>Termo de Compartilhamento de Dados.</strong>
			</Body.Text>
			<Body.Text style={{ marginBottom: 5 }}>
				Como Encarregado de Dados da sua empresa, pedimos que leia e dê
				ciência acerca do <strong>Termos de Privacidade</strong> que
				está presente no link abaixo.
			</Body.Text>
			<Button
				text="Visualizar Termo de Compartilhamento de Dados"
				action={() =>
					openLink(
						'https://www.tectrolnet.com.br/politica-de-privacidade/'
					)
				}
			/>
			<Body.Text style={{ marginBottom: 5 }}>
				Caso você não seja o Encarregado de Dados, por favor, entre em
				contato conosco. O aceite do termo será válido para toda sua
				empresa.
			</Body.Text>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
				onClick={() => toggleAccept(!accept)}
			>
				<Body.ToggleBtn active={accept}>
					{accept ? <Body.Icon src={CheckIcon} /> : null}
				</Body.ToggleBtn>
				<Body.Text style={{ width: 'calc(100% - 40px)' }}>
					Eu, {nome}, Encarregado de Dados da {filial}, aceito o Termo
					de Compartilhamento de Dados.
				</Body.Text>
			</div>
			<Button text="Enviar" action={() => action()} disabled={!accept} />
		</Body.Content>
	);
};

type EmployeeProps = {
	action: any;
};

const Employee: React.FC<EmployeeProps> = ({ action }) => {
	return (
		<Body.Content>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginBottom: 35,
					justifyContent: 'space-between',
				}}
			>
				<Body.SubTitle style={{ fontWeight: 300, marginBottom: 0 }}>
					Termo de Compartilhamento de Dados
				</Body.SubTitle>
			</div>
			<Body.Text style={{ marginBottom: 5 }}>
				Para cumprir com a{' '}
				<strong>Lei Geral de Proteção de Dados</strong>, solicitamos que
				nossos clientes aceitem nosso
				<strong>Termo de Compartilhamento de Dados.</strong>
			</Body.Text>
			<Body.Text style={{ marginBottom: 5 }}>
				A sua empresa ainda <strong>não aceitou</strong> os nossos
				termos. Por conta disso, seu acesso aos nossos sistemas está
				bloqueado.
			</Body.Text>
			<Body.Text style={{ marginBottom: 5 }}>
				Por favor, peça para que o Encarregado de Dados da sua empresa
				entre no Conecta e aceite os termos para liberar seu acesso.
			</Body.Text>
			<Button text="Fazer logout" action={() => action()} />
		</Body.Content>
	);
};

const PrivacyCompany = () => {
	const location = useLocation();
	const params = (location.state as {
		nivel: number;
		nome: string;
		filial: string;
		idFilial: string;
	}) || { nivel: 0, nome: '', filial: '', idFilial: '' };
	const selectedUnity = useSelector(
		(state: State) => state.selectedUnity.selectedUnity
	);

	const [loading, toggleLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const accept = () => {
		toggleLoading(true);
		axios
			.post(
				defines.apiURL +
					'/api/DataSharing?idFilial=' +
					Functions.ReplaceSpecialCharacters(
						selectedUnity || params.idFilial
					),
				{},
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				navigate('/menu');
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const logout = () => {
		axios
			.get(defines.apiURL + '/api/Logout', { withCredentials: true })
			.then((response) => {
				navigate('/');
			})
			.catch((error) => {
				if (error.response.data.code === 'ACS-9') {
					navigate('/');
					return;
				}
				dispatch({
					type: 'ADD_NOTIFICATION',
					title: 'Erro',
					status: 3,
					text: error.response.data.message,
				});
			})
			.finally(() => {
				localStorage.removeItem('@logged');
				localStorage.removeItem('persist:root');
			});
	};

	const getContent = () => {
		switch (params.nivel) {
			case 1:
				return <Employee action={logout} />;
			case 2:
				return (
					<DPO
						nome={params.nome!}
						filial={params.filial!}
						action={accept}
					/>
				);
		}
	};

	return (
		<Body.Container>
						<Body.TitleContent>
				<Body.Row
					style={{ justifyContent: 'center', marginTop: '30px' }}
				>
					<Body.CompanyLogo
						style={{ height: '80px' }}
						src={LogoConecta}
					/>
				</Body.Row>
			</Body.TitleContent>
			{getContent()}
			{loading ? <Loading /> : null}
		</Body.Container>
	);
};

export default PrivacyCompany;
