import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Types from '../../../../utils/types/Types';
import * as Body from '../Styles';
import * as defines from '../../../../utils/defines/Defines';
import * as Functions from '../../../../utils/functions/Functions';
import Button from '../../../components/Buttons/Button/Button';
import { State } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { AcessLevelProps } from '../../../redux/acessLevel/actionsType';
import axios from 'axios';
import { verifyEmail } from '../../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import CheckIcon from '../../../../assets/icons/checkIconMaterial.svg';
import CloseIcon from '../../../../assets/icons/closeIconMaterial.svg';
import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG/Button';

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type SelectUnitProps = {
	setFilial: (filial: FilialProps) => void;
	toggle: Dispatch<SetStateAction<boolean>>;
	filiais: FilialProps[];
	loading: boolean;
};

const SelectUnity: React.FC<SelectUnitProps> = ({
	setFilial,
	toggle,
	filiais,
	loading,
}) => {
	const [filteredFiliais, setFiltered] = useState<FilialProps[]>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		setFiltered(filiais);
	}, [dispatch, navigate, filiais]);

	const select = (filial: FilialProps) => {
		setFilial(filial);
		toggle(false);
	};

	const sortData = (data: FilialProps[]) => {
		return data.sort((a, b) => {
			if (a.nomeEmpresa < b.nomeEmpresa) return -1;
			if (a.nomeEmpresa > b.nomeEmpresa) return 1;
			if (a.statusAssinatura && !b.statusAssinatura) return -1;
			if (!a.statusAssinatura && b.statusAssinatura) return 1;
			if (a.nomeFilial < b.nomeFilial) return -1;
			if (a.nomeFilial > b.nomeFilial) return 1;
			return 0;
		});
	};

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFiltered(sortData(filiais));
			return;
		}

		const filtered = filiais.filter((item) =>
			item.nomeFilial.toUpperCase().includes(name.toUpperCase())
		);
		setFiltered(filtered);
	};

	const toggleModal = () => {
		navigate('/aplicativos');
	};

	return (
		<Modal
			title="Selecionar Filial"
			loading={loading}
			toggleModal={toggleModal}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Body.FindContent style={{ width: '100%' }}>
					<Body.Find
						onChange={(e) => filterData(e.target.value)}
						style={{ maxWidth: '100%', width: '100%' }}
					/>
					<Body.ModalIcon src={SearchIcon} />
				</Body.FindContent>
				<Body.Scroll>
					{filteredFiliais ? (
						filteredFiliais.map((item, index, array) => {
							const showSeparator =
								index > 0 &&
								item.nomeEmpresa !==
									array[index - 1].nomeEmpresa;
							return (
								<React.Fragment key={index.toString()}>
									{showSeparator && <Body.Separator />}
									<Body.SelectUser
										onClick={() => {
											select(item);
										}}
									>
										<Body.CompanyStatus>
											{item.statusAssinatura ? (
												<Body.GreenIcon>
													<img
														src={CheckIcon}
														alt="Check Icon"
													/>
												</Body.GreenIcon>
											) : (
												<Body.RedIcon>
													<img
														src={CloseIcon}
														alt="Close Icon"
													/>
												</Body.RedIcon>
											)}
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Body.Text>
													{item.nomeFilial}
												</Body.Text>
												<Body.Empresa>
													{item.nomeEmpresa}
												</Body.Empresa>
											</div>
										</Body.CompanyStatus>

									</Body.SelectUser>
								</React.Fragment>
							);
						})
					) : (
						<ButtonWithoutBG
							text="Não existem filiais disponíveis. Clique para retornar para Apps e Planos"
							action={() => {
								navigate('/aplicativos');
							}}
						/>
					)}
				</Body.Scroll>
			</div>
		</Modal>
	);
};

export default SelectUnity;
