import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Types from '../../../../utils/types/Types';
import * as Body from '../Styles';
import * as defines from '../../../../utils/defines/Defines';
import * as Functions from '../../../../utils/functions/Functions';
import Button from '../../../components/Buttons/Button/Button';
import { State } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { AcessLevelProps } from '../../../redux/acessLevel/actionsType';
import axios from 'axios';
import { verifyEmail } from '../../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';

type ModalDeleteProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	idUsuario: string;
	nome: String;
	action: any;
	nomeFilial?: string;
};

export const ModalDeleteUser: React.FC<ModalDeleteProps> = ({
	toggle,
	idUsuario,
	nome,
	action,
	nomeFilial = 'atual',
}) => {
	return (
		<Modal title="Desvincular Usuário">
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Body.Text style={{ marginBottom: '20px' }}>
					Tem certeza que deseja desvincular o usuário{' '}
					<strong>{nome}</strong>?
					<br />
					Essa ação removerá o usuário da filial{' '}
					<strong>{nomeFilial}</strong>.
				</Body.Text>
				<Body.Row style={{ justifyContent: 'center' }}>
					<Button
						text="Desvincular"
						width={100}
						color="red"
						action={() => action()}
					/>
					<Button
						text="Cancelar"
						width={100}
						action={() => toggle(false)}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};
