import React, { useEffect, useRef, useState } from 'react';
import * as Body from './Styles';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DataPicker.css';
import { ptBR } from 'date-fns/locale/pt-BR';

type InputProps = {
	text: string;
	setText: (value: string) => void;
	title?: string;
	disabled?: boolean;
	removeHours?: boolean;
};

const DatetimeInput: React.FC<InputProps> = ({ text, setText, title, disabled, removeHours }) => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const datePickerRef = useRef<DatePicker>(null);

	// Atualiza data inicial com base no `text`
	useEffect(() => {
		if (text) setStartDate(new Date(text));
	}, [text]);

	// Atualiza `text` quando `startDate` mudar
	useEffect(() => {
		if (startDate) {
			const adjustedDate = removeHours ? resetHours(startDate) : startDate;
			setText(adjustedDate.toISOString());
		} else {
			setText('');
		}
	}, [startDate, setText, removeHours]);

	// Zera horas, minutos, segundos e milissegundos de uma data
	const resetHours = (date: Date): Date => {
		const adjustedDate = new Date(date);
		adjustedDate.setHours(0, 0, 0, 0);
		return adjustedDate;
	};

	return (
		<Body.Content>
			{title && (
				<Body.TitleContent>
					<Body.Text>{title}</Body.Text>
				</Body.TitleContent>
			)}
			<DatePicker
				ref={datePickerRef}
				disabled={disabled}
				selected={startDate}
				onChange={(date) => setStartDate(date as Date | null)}
				showTimeSelect={false} // Não exibe seleção de horas
				dateFormat="P" // Apenas data
				locale="pt-BR"
				withPortal
			/>
		</Body.Content>

	);
};

export default DatetimeInput;
