import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	display: flex;
	flex-direction: column;
	height: 100vh; /* Altura da viewport para evitar compressão */
	overflow: hidden; /* Impedir scroll geral */
`;

export const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center; /* Centraliza horizontalmente */
	justify-content: flex-start; /* Ajusta a posição vertical */
	padding: 20px;
	overflow-y: auto; /* Permite rolagem vertical */
	height: 100vh; /* Define a altura baseada na viewport */
	box-sizing: border-box;
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: #000;
	margin-bottom: 10px;
`;

export const Row = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	grid-gap: 10px 0px;
	min-height: 80px;
`;

export const TextRow = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	grid-gap: 10px 0px;
	min-height: 50px;
	height: 200px;
`;

export const ResultContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%; /* Garante largura total */
`;

export const SubTitle = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	width: 100%; /* Garante largura total */
	text-align: left; /* Alinha o texto à esquerda */
	margin-bottom: 5px;
`;

export const Text = styled.a`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 14px;
	color: #000;
	width: 100%; /* Garante largura total */
	text-align: left; /* Alinha o texto à esquerda */
	margin-bottom: 5px;
`;

export const HalfContent = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	max-height: 100%;
`;

export const Photo = styled.img`
	width: 100%;
	max-width: 180px; /* Limite de largura */
	height: auto; /* Garante que a altura seja proporcional à largura */
	aspect-ratio: 1 / 1; /* Garante que a largura seja sempre igual à altura */
	border-radius: 50%;
	object-fit: cover; /* Garante que o conteúdo se ajuste corretamente */
	display: block; /* Remove gaps adicionais causados pelo comportamento inline de <img> */
	margin: 0 auto; /* Centraliza horizontalmente */
`;

const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`;

export const TextError = styled.a`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Red};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const TextWarning = styled.a`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Yellow};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const EditPictureIcon = styled.div`
	background-color: ${defines.BlueTec};
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%; /* Para deixar o ícone circular */
	position: absolute; /* Para posicionamento relativo à foto */
	bottom: 10px; /* Ajuste para alinhar na borda inferior */
	right: 10px; /* Ajuste para alinhar na borda direita */
	cursor: pointer;
`;


export const Scroll = styled.div`
	max-height: 85vh; /* Ajuste a altura para ocupar a maior parte da tela */
	padding-top: 10px;
	overflow-y: auto; /* Permite rolagem vertical */
	::-webkit-scrollbar {
		display: none; /* Esconde a barra de rolagem no Webkit */
	}
	box-sizing: border-box;
`;
