import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ComercialIcon from '../../../../assets/icons/supervisor-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/tecnico-icon.svg';
import SuporteIcon from '../../../../assets/icons/supervisor-icon.svg';
import PadraoIcon from '../../../../assets/icons/tecnico-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import { State } from '../../../redux/store';

export const CargoTectrol = {
	Comercial: 1,
	Engenheiro: 2,
	Suporte: 3,
	Padrão: 4,
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	fotoUsuario: string | null;
	ddd: string;
	celular: string;
	email: string;
	tipoTectrol: number;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	usuario: UsuarioProps;
	regenerate: () => void;
};

const EditUser: React.FC<SelectUnitProps> = ({
	toggle,
	usuario,
	regenerate,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [selected, setSelected] = useState(usuario.tipoTectrol);
	const [loading, toggleLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const changeUser = () => {
		toggleLoading(true);
		axios
			.put(
				defines.apiURL +
					'/api/TectrolCRUD' +
					'?userTypeRequest=' +
					userType,
				{
					idUsuario: usuario.idUsuario,
					tipoTectrol: selected,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				toggle(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Tipo alterado com sucesso!',
				});
				regenerate();
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	return (
		<Modal title="Mudar permissão" subtitle={usuario.nomeCompleto}>
			<>
				<Body.Row style={{ marginTop: '15px' }}>
					<Body.SelectCard
						active={selected === CargoTectrol.Comercial}
						onClick={() => setSelected(CargoTectrol.Comercial)}
					>
						<Body.IconChangePermission src={ComercialIcon} />
						<Body.Text>Comercial</Body.Text>
					</Body.SelectCard>
					<Body.SelectCard
						active={selected === CargoTectrol.Engenheiro}
						onClick={() => setSelected(CargoTectrol.Engenheiro)}
					>
						<Body.IconChangePermission src={EngenheiroIcon} />
						<Body.Text>Engenharia</Body.Text>
					</Body.SelectCard>
					<Body.SelectCard
						active={selected === CargoTectrol.Suporte}
						onClick={() => setSelected(CargoTectrol.Suporte)}
					>
						<Body.IconChangePermission src={SuporteIcon} />
						<Body.Text>Suporte</Body.Text>
					</Body.SelectCard>
					<Body.SelectCard
						active={selected === CargoTectrol.Padrão}
						onClick={() => setSelected(CargoTectrol.Padrão)}
					>
						<Body.IconChangePermission src={PadraoIcon} />
						<Body.Text>Padrão</Body.Text>
					</Body.SelectCard>
				</Body.Row>
				<Body.Row
					style={{
						gridGap: '0px 10px',
						justifyContent: 'center',
						marginTop: '20px',
					}}
				>
					<Button
						text="Alterar"
						color="green"
						action={() => {
							changeUser();
						}}
						width={120}
						loading={loading}
					/>
					<Button
						text="Cancelar"
						color="red"
						action={() => toggle(false)}
						disabled={loading}
						width={120}
					/>
				</Body.Row>
			</>
		</Modal>
	);
};

export default EditUser;
