import React, { useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Types from '../../../../utils/types/Types';
import * as Body from './Styles';
import * as defines from '../../../../utils/defines/Defines';
import * as Functions from '../../../../utils/functions/Functions';
import Button from '../../../components/Buttons/Button/Button';
import { State } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import ButtonWithoutBG from '../../Buttons/ButtonWithoutBG/Button';

const SelectUnity: React.FC = () => {
	const unity = useSelector((state: State) => state.ReqUnity);
	const userType = useSelector((state: State) => state.userType.userType);
	const [loading, setLoading] = useState(false);
	const [filteredFiliais, setFiltered] = useState<Types.Unidade[]>([]);
	const [filiais, setFiliais] = useState<Types.Unidade[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [skip, setSkip] = useState(false);
	const [firstExecution, setFirstExecution] = useState(true);
	const location = useLocation();
	const [depth, setDepth] = useState(10);

	useEffect(() => {
		if (userType !== defines.TypeSidebar.DEFAULT_USER || !unity) return;
		refresh();
	}, [unity]);

	const select = (id: string, nome: string) => {
		dispatch({ type: 'SET_SELECTED_UNITY', selectedUnity: id });
		dispatch({ type: 'REMOVE_UNITY' });
		navigate('/minha-conta');
	};

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFiltered(filiais);
			return;
		}
		const filtered = filiais.filter((item) =>
			item.nomeFilial.toUpperCase().includes(name.toUpperCase())
		);
		setFiltered(filtered);
	};

	const refresh = () => {
		setDepth(depth - 1);
		if (depth === 0) return;

		if (
			[
				'/',
				'/login',
				'/recuperar',
				'/ativar-conta',
				'/privacidade',
				'/privacidade-empresa',
				'/cadastro',
				'/permissao',
				'/menu/vincular-filial',
			].includes(location.pathname)
		) {
			return;
		}

		setIsLoaded(false);
		axios
			.get(
				`${defines.apiURL}/api/Home?home=1&userTypeRequest=${userType}`,
				{ withCredentials: true }
			)
			.then((response) => {
				const content = response.data.content;
				if (!content || content.length === 0) {
					axios
						.get(defines.apiURL + '/api/Home?userTypeSelection=1', {
							withCredentials: true,
						})
						.then((response) => {
							if (!response.data.content.empresarial) {
								dispatch({
									type: 'ADD_NOTIFICATION',
									status: 2,
									title: 'Você não possui filiais vinculadas!',
									text: 'Você pode vincular uma filial clicando no botão abaixo.',
								});
								setDepth(10);
							} else {
								refresh();
							}
						})
						.catch((error) => {
							Functions.ResolveErrors(
								error.response.status,
								error.response.data.code,
								error.response.data.message,
								navigate,
								dispatch,
								error.response.data.content
							);
						});
					setIsLoaded(true);
				} else if (content.length === 1) {
					setSkip(true);
					select(content[0].idFilial, content[0].nomeFilial);
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 1,
						title: 'Filial encontrada!',
						text:
							'Entrando como usuário da filial: ' +
							content[0].nomeFilial,
					});
					setDepth(10);
				} else {
					setFiliais(content);
					setFiltered(content);
					setDepth(10);
				}
				setIsLoaded(true);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response?.status || 500,
					error.response?.data?.code || '',
					error.response?.data?.message || 'Erro desconhecido',
					navigate,
					dispatch,
					error.response?.data?.content || []
				);
				setIsLoaded(true);
			});
	};

	if (
		!unity ||
		defines.TypeSidebar.DEFAULT_USER !== userType ||
		(!isLoaded && !loading)
	) {
		return null;
	}

	return (
		<Modal title="Selecionar Filial" loading={loading}>
			<>
				<Body.FindContent style={{ width: '100%' }}>
					<Body.Find
						onChange={(e) => filterData(e.target.value)}
						style={{ maxWidth: '100%', width: '100%' }}
					/>
					<Body.Icon src={SearchIcon} />
				</Body.FindContent>
				<Body.Scroll>
					{filteredFiliais.length > 0 ? (
						filteredFiliais.map((item, index) => (
							<Body.SelectUser
								key={index}
								onClick={() => {
									select(item.idFilial, item.nomeFilial);
								}}
							>
								<Body.Text>{item.nomeFilial}</Body.Text>
							</Body.SelectUser>
						))
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginTop: '20px',
								gap: '20px',
							}}
						>
							<Body.Text>Nenhuma filial encontrada.</Body.Text>
							<Button
								text="Atualizar lista"
								action={() => {
									setDepth(10);
									refresh();
								}}
							/>
						</div>
					)}
				</Body.Scroll>
			</>
		</Modal>
	);
};

export default SelectUnity;
