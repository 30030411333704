import React, { useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import CodeInput from '../../components/Inputs/InputCode/Input';
import Button from '../../components/Buttons/Button/Button';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LogoConecta from '../../../assets/images/logoConecta.png';

const ActivateAccount = () => {
	const [tfaCode, setCodigo] = useState('');
	const [loading, toggleLoading] = useState(false);
	const [loadingCode, toggleCode] = useState(false);
	const [time, setTime] = useState(120);
	const [lock, toggleLock] = useState(false);
	const [params] = useSearchParams();
	const email = params.get('email');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (time === 0) {
			toggleLock(false);
			return;
		}
		const interval = setInterval(() => {
			setTime((seconds) => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [time]);

	const tryResend = () => {
		toggleCode(true);
		axios
			.get(defines.apiURL + '/api/LoginAccountActivation', {
				withCredentials: true,
			})
			.then((response) => {
				setTime(120);
				toggleLock(true);
				toggleCode(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					title: 'Código enviado',
					status: 1,
				});
			})
			.catch((error) => {
				toggleCode(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const tryActivate = () => {
		toggleLoading(true);
		axios
			.post(defines.apiURL + '/api/LoginAccountActivation', {
				tfaCode,
				api: process.env.REACT_APP_API_URL!,
			},{ withCredentials: true })
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: 'Conta ativada',
				});
				navigate('/menu');
				toggleLoading(false);
				return;
			})
			.catch((error) => {
				const status = error.response.status;
				const code = error.response.data.code;
				const message = error.response.data.message;
				toggleLoading(false);
				switch (status) {
					case 400:
						if (code === 'ACS-11') {
							dispatch({
								type: 'ADD_NOTIFICATION',
								status: 2,
								title: '',
								text: message,
							});
							navigate('/lgpd');
							return;
						}
						dispatch({
							type: 'ADD_NOTIFICATION',
							status: 3,
							title: '',
							text: message,
						});
						break;
				}
				return;
			});
	};

	useEffect(() => {
		toggleCode(true);
		axios
			.get(defines.apiURL + '/api/LoginAccountActivation', {
				withCredentials: true,
			})
			.then((response) => {
				setTime(120);
				toggleLock(true);
				toggleCode(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					title: 'Código enviado',
					status: 1,
				});
			})
			.catch((error) => {
				toggleCode(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	}, [dispatch, email, navigate]);

	return (
		<Body.Container>
			<Body.TitleContent>
				<Body.Row
					style={{ justifyContent: 'center', marginTop: '30px' }}
				>
					<Body.CompanyLogo
						style={{ height: '80px' }}
						src={LogoConecta}
					/>
				</Body.Row>
			</Body.TitleContent>
			<Body.Content>
				<Body.Text>
					Sua conta ainda não está ativa! Enviamos um código de
					verificação para seu email. Insira para prosseguir.
				</Body.Text>
				<CodeInput setText={setCodigo} action={() => tryActivate()} />
				<Button
					text="Ativar"
					action={() => tryActivate()}
					loading={loading}
				/>
				{lock ? (
					<Body.Text>
						Aguarde {Functions.getTime(time)} para enviar novamente
					</Body.Text>
				) : (
					<ButtonWithoutBG
						text="Reenviar código"
						loading={loadingCode}
						action={() => tryResend()}
					/>
				)}
			</Body.Content>
		</Body.Container>
	);
};

export default ActivateAccount;
