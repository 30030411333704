import styled from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Card = styled.div`
	width: 280px;
	height: 80%;
	border-radius: 20px;
	border: none;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	background-color: ${defines.BackgroundColor};
	-webkit-box-shadow:
		10px 10px 30px 1px rgba(211, 211, 212, 0.4),
		-10px -10px 30px 1px rgba(255, 255, 255, 0.8);
	box-shadow:
		-10px -10px 30px 1px rgba(255, 255, 255, 0.8),
		10px 10px 30px 1px rgba(211, 211, 212, 0.4);
	position: absolute;
	left: 60px;
`;

export const Photo = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 40px;
`;
export const Title = styled.a`
	font-family: 'Comfortaa';
	font-weight: medium;
	fonts-size: 18px;
	max-width: 120px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 10px 0px;
`;

export const Image = styled.img`
	width: 150px;
	height: 100px;
`;

export const BottomBar = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgba(10, 10, 10, 0.4);
`;
export const Subtitle = styled.a`
    font-family: "Comfortaa";
    font-weight: medium;
    font-size: 12px;
    margin-top: -8px;
    color: #6D7989;
    max-width: 120px;

`;

export const ProfileInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: left;
	grid-gap: 10px 0px;
`;

export const ProfileCard = styled.div`
	display: flex;
	grid-gap: 0px 20px;
	align-items: center;
	justify-content: space-between;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 4px;
`;

export const UnityBadge = styled.div`
	max-width: 120px;
	font-family: 'Comfortaa';
	font-size: 12px;
	padding: 4px;
	padding-left: 12px;
	padding-right: 12px;
	color: white;
	background-color: #6d7989;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BadgeIcon = styled.img`
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	max-width: 16px;
	max-height: 16px;
`;
