import React, { ReactChild } from 'react';
import * as Body from './Styles';

import AddIcon from '../../../../assets/icons/add-icon.svg';
import CancelIcon from '../../../../assets/icons/close-icon.svg';
import Spinner from '../../Spinner/Spinner';

type Props = {
	toggleModal?: any;
	title?: string;
	subtitle?: string;
	remove?: any;
	save?: any;
	add?: any;
	loading?: boolean;
	change_permission?: {
		function: string;
		app: string;
	};
	children?: ReactChild;
};

const Modal: React.FC<Props> = ({
	toggleModal,
	title,
	subtitle,
	save,
	loading,
	remove,
	children,
	add,
	change_permission,
}) => {
	const getNameFunction = (text: string) => {
		switch (text) {
			case 'Administrador':
				return 'Administradores';
			case 'Supervisor':
				return 'Supervisores';
			case 'Técnico':
				return 'Técnicos';
			default:
				return text;
		}
	};

	return (
		<Body.Container>
			<Body.Modal>
				<Body.Header>
					<Body.Row>
						{change_permission ? (
							<Body.Title>
								Editando{' '}
								<strong>
									{getNameFunction(
										change_permission.function
									)}{' '}
								</strong>
								da <br /> aplicação{' '}
								<strong>{change_permission.app}</strong>
							</Body.Title>
						) : null}
						{title ? (
							<Body.Title>
								{title}
								{subtitle ? (
									<Body.SubTitle>{subtitle}</Body.SubTitle>
								) : null}{' '}
							</Body.Title>
						) : null}
					</Body.Row>
					<Body.Row>
						{add ? (
							<Body.Button color="green" onClick={add}>
								<Body.BtnIcon src={AddIcon} />
							</Body.Button>
						) : null}
						{remove ? <Body.Button color="red" /> : null}
						{toggleModal ? (
							<Body.Button onClick={() => toggleModal(false)}>
								<Body.BtnIcon src={CancelIcon} />
							</Body.Button>
						) : null}
					</Body.Row>
				</Body.Header>
				{loading ? (
					<Body.Row style={{ justifyContent: 'center' }}>
						<Spinner size={40} blue={true} />
					</Body.Row>
				) : (
					children
				)}
			</Body.Modal>
		</Body.Container>
	);
};

export default Modal;
