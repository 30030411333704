import styled from 'styled-components';
import * as defines from '../../../../utils/defines/Defines';

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	width: 100vw;
	height: 100vh;
	padding: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: flex-end;
`;

export const Content = styled.div`
	width: calc(100vw - 420px);
	height: 100%;
	display: flex;
	grid-gap: 10px 10px;
	justify-content: space-around;
	align-items: center;
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: #000;
	margin-bottom: 10px;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 20px;
`;

export const ResultContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SubTitle = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
export const Text = styled.a`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 14px;
	color: #000;
	margin-bottom: 5px;
`;
export const Cargo = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
export const HalfContent = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	max-height: 100%;
`;

export const Photo = styled.img`
	width: 180px;
	height: 180px;
	border-radius: 90px;
`;

export const Find = styled.input`
	width: 30px;
	height: 30px;
	max-width: 200px;
	border-radius: 15px;
	border: 1px solid #1080d6;
	padding-left: 30px;
	font-family: 'Nunito';
	transition: 0.5s;
	background: none;
	z-index: 5;
	:focus {
		transition: 0.5s;
		width: 300px;
	}
`;

export const FindContent = styled.div`
	min-width: 30px;
	position: relative;
`;

export const Icon = styled.img`
	top: 7.5px;
	left: 7.5px;
	width: 15px;
	height: 15px;
	position: absolute;
	z-index: 3;
`;

export const Scroll = styled.div`
	width: 100%;
	padding-top: 10px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	box-sizing: border-box;
	max-height: 50vh;
`;

export const SelectUser = styled.div`
	grid-gap: 0px 10px;
	width: 100%;
	height: 60px;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	cursor: pointer;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	transition: 0.5s;
	justify-content: space-between;
	:hover {
		transition: 0.5s;
		background: rgba(0, 0, 0, 0.02);
	}
`;
