import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import Input from '../../components/Inputs/Input/Input';
import * as Types from '../../../utils/types/Types';
// import ButtonWithoutBG from "../../components/Buttons/ButtonWithoutBG/Button";
// import imageCompression from "browser-image-compression";
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import * as Modals from './modals/Modals';
import { State } from '../../redux/store';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';

type CompanyDataProps = {
	type: number;
	empresa: string;
	cnpj: string;
	filiais: Types.Unidade[];
};

const CompanyData: React.FC<CompanyDataProps> = ({
	type,
	empresa,
	cnpj,
	filiais,
}) => {
	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	return (
		<>
			<Body.TextRow>
			<Body.Title style={{ marginTop: '20px' }}>DADOS DA EMPRESA</Body.Title>
			</Body.TextRow>
			<Body.TextRow>
				<Body.ResultContent>
					<Body.SubTitle>Nome</Body.SubTitle>
					<Body.Text>{empresa}</Body.Text>
				</Body.ResultContent>
			</Body.TextRow>
			<Body.TextRow>
				<Body.ResultContent>
					<Body.SubTitle>CNPJ</Body.SubTitle>
					<Body.Text>{formatCNPJ(cnpj)}</Body.Text>
				</Body.ResultContent>
			</Body.TextRow>
			{type === defines.TypeSidebar.DEFAULT_USER ? (
				<Body.TextRow style={{ height: '100px', minHeight: '100px'}}>
					<Body.ResultContent>
						<Body.SubTitle>Unidades vinculadas à você</Body.SubTitle>
						<Body.Text>
						{filiais.map((item, index) => {
							return (
								<React.Fragment key={index}>
									{item.nomeFilial}
									{index !== filiais.length - 1 ? ', ' : ''}
								</React.Fragment>
							);
						})}
						</Body.Text>
					</Body.ResultContent>
				</Body.TextRow>
			) : null}
			<br />
			<Body.SubTitle>
				Você não pode alterar os dados da empresa.
				<br />
				Se algo estiver divergente, por favor, entre em contato.
			</Body.SubTitle>
		</>
	);
};

export default CompanyData;
