import styled from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	width: 100vw;
	height: 100vh;
	padding: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: flex-end;
`;

export const Content = styled.div`
	width: calc(100vw - 420px);
	height: 100%;
	display: flex;
	grid-gap: 30px 30px;
	justify-content: safe center;
	align-items: center;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 24px;
	color: #000;
	margin-bottom: 10px;
	height: 40px;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 20px;
`;

export const ResultContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SubTitle = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
	height: 80px;
`;
export const Text = styled.a`
	width: 100%;
	text-align: left;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 14px;
	color: #000;
	margin-left: 100px;
`;
export const HalfContent = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	max-height: 100%;
`;

export const Photo = styled.img`
	width: 180px;
	height: 180px;
	border-radius: 90px;
`;

export const Card = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 10px 0px;
	text-align: center;
`;

export const Icon = styled.img`
	width: 100px;
	height: 100px;
	min-width: 100px;
	max-width: 100px;
	min-height: 100px;
	max-height: 100px;
	border-radius: 12px;
`;

export const Filial = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: ${defines.BlueTec};
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-left: 100px;
`;
