import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import Input from '../../components/Inputs/Input/Input';
import * as Types from '../../../utils/types/Types';
// import imageCompression from "browser-image-compression";
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { State } from '../../redux/store';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import Avator from '../../../assets/images/avatar.png';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import DHMIcon from '../../../assets/icons/dhm-icon.svg';
import IndustryIcon from '../../../assets/icons/industry-icon.png';
import ChartIcon from '../../../assets/icons/chart-icon.png';
import DesktopOnly from './Modals/DesktopOnly';

const AppsPlans = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const userType = useSelector((state: State) => state.userType.userType);

	const [showDesktopOnly, toggleDesktopOnly] = useState(false);
	const [loading, toggleLoading] = useState(true);

	useEffect(() => {}, [dispatch, navigate]);

	const openApp = (link: string) => {
		window.open(link, '_blank');
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.APPS_AND_PLANS}
			/>
			<Body.Row style={{ width: '100%', justifyContent: 'center' }}>
			<Body.PageTitle>Aplicativos</Body.PageTitle>
				<Body.SubTitle>
					Aqui estão as aplicações disponíveis.
				</Body.SubTitle>
			</Body.Row>
			<Body.Scroll>

			<Body.Card>
					<div style={{ display: 'flex', alignItems: 'left', marginBottom: '10px' }}>
						<Body.Icon src={DHMIcon} />
						<Body.Column style={{ marginLeft: '10px' }}>
							<Body.Title style={{ textAlign: 'left' }}>DHM4.0</Body.Title>
							<Body.SubTitleCard style={{ textAlign: 'left' }}>
								Monitore e personalize o sistema DHM4.0 operando em
								sua usina
							</Body.SubTitleCard>
						</Body.Column>
					</div>
					<Button
						text="Abrir Aplicativo"
						action={() =>
							openApp(
								'https://www.dhm.dinamoautomacao.com.br'
							)
						}
					/>
					<>
						<Button
							color="yellow"
							text="Gerenciar Planos"
							action={() => {toggleDesktopOnly(true)}}
						/>
						<Button
							color="green"
							text="Gerenciar Empresas"
							action={() =>
								navigate('/aplicativos/assinaturas', {
									state: { app: 'DHM' },
								})
							}
						/>
					</>
				</Body.Card>
				{/* <Body.Card>
			<Body.Icon src={ChartIcon} />
			<Body.Title>
				Relatórios
			</Body.Title>
			<Body.SubTitleCard>
			Veja os relatórios das Ordens de Serviços realizadas pelo grupo Tectrol e Dínamo
			</Body.SubTitleCard>
			<Button text="Abrir Aplicativo" action={()=>openApp("")}/>
			<>
				<Button color="yellow" text="Gerenciar Planos" action={() => navigate('/aplicativos/planos', { state: { app: 'REL' } })}/>
				<Button color="green" text="Gerenciar Empresas" action={() => navigate('/aplicativos/assinaturas', { state: { app: 'REL' } })}/>
			</>
		</Body.Card> */}
				<Body.Card>
					<div style={{ display: 'flex', alignItems: 'left', marginBottom: '10px' }}>
						<Body.Icon src={IndustryIcon} />
						<Body.Column style={{ marginLeft: '10px' }}>
							<Body.Title style={{ textAlign: 'left' }}>IndustrySight</Body.Title>
							<Body.SubTitleCard style={{ textAlign: 'left' }}>
								Veja a operação da sua indústria em tempo real
							</Body.SubTitleCard>
						</Body.Column>
					</div>
					<Button
						text="Abrir Aplicativo"
						action={() => openApp('')}
					/>
					<>
						<Button
							color="yellow"
							text="Gerenciar Planos"
							action={() => {toggleDesktopOnly(true)}}
						/>
						<Button
							color="green"
							text="Gerenciar Empresas"
							action={() =>
								navigate('/aplicativos/assinaturas', {
									state: { app: 'INS' },
								})
							}
						/>
					</>
				</Body.Card>
			</Body.Scroll>
		{showDesktopOnly && <DesktopOnly toggle={toggleDesktopOnly} />}
		</Body.Container>
	);
};

export default AppsPlans;
