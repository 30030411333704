import React, { useEffect, useRef, useState } from 'react';
import * as Body from './Styles';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DataPicker.css';
import { ptBR } from 'date-fns/locale/pt-BR';

import dateIcon from '../../../assets/icons/edit-calendar-material-icon.svg';
import dateDisabledIcon from '../../../assets/icons/calendario-cinza-material-icon.svg';

registerLocale('pt-BR', ptBR);

type InputProps = {
	text: string;
	placeholder?: string;
	setText: (value: string) => void;
	width?: number;
	error?: boolean;
	title?: string;
	limit?: number;
	action?: any;
	onClick?: any;
	disabled?: boolean;
	value?: string;
	removeHours?: boolean;
};

const DatetimeInput: React.FC<InputProps> = ({
	text,
	setText,
	title,
	onClick,
	disabled,
	removeHours,
}) => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const datePickerRef = useRef<DatePicker>(null);

	// Atualiza a data inicial quando `text` mudar
	useEffect(() => {
		if (text) {
			try {
				const parsedDate = new Date(text); // Garantir que o formato seja compatível
				setStartDate(parsedDate);
			} catch (error) {
				console.error('Data inválida:', error);
			}
		}
	}, [text]);

	// Atualiza `text` (ISO string) quando `startDate` mudar
	useEffect(() => {
		if (startDate) {
			setText(startDate.toISOString()); // ISO é sempre UTC
		} else {
			setText(''); // Se não houver data, limpa o valor
		}
	}, [startDate, setText]);

	const handleChange = (date: Date | null) => {
		setStartDate(date); // Atualiza o estado local
	};

	const handleIconClick = () => {
		if (datePickerRef.current) {
			datePickerRef.current.setFocus();
		}
	};

	const CustomInput = React.forwardRef((props: any, ref) => (
		<input {...props} ref={ref} readOnly />
	));

	return (
		<Body.Content>
			{title && (
				<Body.TitleContent>
					<Body.Text>{title}</Body.Text>
				</Body.TitleContent>
			)}
			<DatePicker
				ref={datePickerRef}
				disabled={disabled}
				selected={startDate}
				onChange={handleChange}
				showTimeSelect={!removeHours}
				dateFormat={removeHours ? 'P' : 'Pp'}
				timeFormat="p"
				timeIntervals={15}
				timeCaption="Hora"
				locale="pt-BR"
				withPortal
				customInput={<CustomInput />}
			/>
		</Body.Content>
	);
};

export default DatetimeInput;
