import React from 'react';
import * as Body from './Styles';
import ConectaIcon from '../../../assets/icons/conecta-icon.svg';

const Loading = () => {
	return (
		<Body.Container>
			<Body.Icon src={ConectaIcon} />
		</Body.Container>
	);
};

export default Loading;
