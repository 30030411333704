import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../../components/Modals/Modal/Modal';
import Input from '../../../components/Inputs/Input/Input';
import * as Body from '../Styles';
import * as Functions from '../../../../utils/functions/Functions';
import * as Defines from '../../../../utils/defines/Defines';
import Button from '../../../components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { State } from '../../../redux/store';

interface BasePlanoProps {
	idPlanoInSight?: string;
	idPlanosDHM?: string;
	idPlanoRelatorios?: string;
	nomePlano: string;
	preco: number;
	createdAt: string;
}

type PlanoProps = BasePlanoProps & {
	[key: string]: any;
	[key: number]: any;
};

type UpdatePlanProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	idPlano: string;
	planos: PlanoProps[];
	regenerate: () => void;
	app: string;
};

export const UpdatePlan: React.FC<UpdatePlanProps> = ({
	toggle,
	idPlano,
	planos,
	regenerate,
	app,
}) => {
	const [loading, toggleLoading] = useState(false);
	const [nomePlano, setNomePlano] = useState('');
	const [quotaUsuarios, setQuotaUsuarios] = useState('');
	const [quotaAtuadorSensor, setQuotaAtuadorSensor] = useState('');
	const [quotaEnvio, setQuotaEnvio] = useState('');
	const [preco, setPreco] = useState('');
	const userType = useSelector((state: State) => state.userType.userType);

	const [originalPlano, setOriginalPlano] = useState<PlanoProps | null>(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	useEffect(() => {
		toggleLoading(true);
		const plano = planos.find((plano) => {
			if (app === 'INS') return plano.idPlanoInSight === idPlano;
			if (app === 'DHM') return plano.idPlanosDHM === idPlano;
			if (app === 'REL') return plano.idPlanoRelatorios === idPlano;
			return false;
		});

		if (plano) {
			setNomePlano(plano.nomePlano);
			setQuotaUsuarios(plano.quotaUsuarios.toString());
			if (app === 'INS') {
				setQuotaAtuadorSensor(plano.quotaAtuadorSensor.toString());
				setQuotaEnvio(plano.quotaEnvio.toString());
			}
			setPreco(plano.preco.toString());
			setOriginalPlano(plano);
		}
		toggleLoading(false);
	}, [dispatch, navigate, idPlano, planos]);

	const updatePlan = () => {
		if (!originalPlano) return;

		toggleLoading(true);

		const payload: any = {};

		// Comparação com os valores originais
		if (nomePlano !== originalPlano.nomePlano)
			payload.nomePlano = nomePlano;
		if (parseInt(quotaUsuarios) !== originalPlano.quotaUsuarios)
			payload.quotaUsuarios = parseInt(quotaUsuarios);
		if (app === 'INS') {
			if (
				parseInt(quotaAtuadorSensor) !==
				originalPlano.quotaAtuadorSensor
			)
				payload.quotaAtuadorSensor = parseInt(quotaAtuadorSensor);
			if (parseInt(quotaEnvio) !== originalPlano.quotaEnvio)
				payload.quotaEnvio = parseInt(quotaEnvio);
		}
		if (parseFloat(preco) !== originalPlano.preco)
			payload.preco = parseFloat(preco);

		// Se nenhum campo foi alterado, não enviar a requisição
		if (Object.keys(payload).length === 0) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 2,
				title: 'Nada a alterar',
				text: 'Nenhuma alteração foi detectada.',
			});
			toggleLoading(false);
			return;
		}

		if (app === 'INS') {
			payload.idPlanoInSight = idPlano;
		} else if (app === 'DHM') {
			payload.idPlanoDHM = idPlano;
		} else if (app === 'REL') {
			payload.idPlanoRelatorios = idPlano;
		}

		axios
			.put(
				Defines.apiURL +
					'/api/PlanosCRUD?userTypeRequest=' +
					userType +
					'&INS=' +
					Functions.ReplaceSpecialCharacters(idPlano) +
					'&api=' +
					app,
				payload,
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Plano atualizado com sucesso',
				});
				toggle(false);
				regenerate();
				toggleLoading(false);
			})
			.catch((error) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 3,
					title: 'Erro',
					text: error.response.data.message,
				});
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const insightPlan = () => {
		return (
			<>
				<Body.Row>
					<Input
						width={340}
						title="Nome do Plano"
						text={nomePlano}
						setText={setNomePlano}
						disabled
					/>
					<Input
						width={140}
						title="Preço"
						text={preco}
						setText={setPreco}
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={120}
						title="Quota de Usuários"
						text={quotaUsuarios}
						setText={setQuotaUsuarios}
					/>
					<Input
						width={220}
						title="Quota de Sensores/ Atuadores"
						text={quotaAtuadorSensor}
						setText={setQuotaAtuadorSensor}
					/>
					<Input
						width={120}
						title="Quota de Envios"
						text={quotaEnvio}
						setText={setQuotaEnvio}
					/>
				</Body.Row>
			</>
		);
	};

	const dhmPlan = () => {
		return (
			<>
				<Body.Row>
					<Input
						width={340}
						title="Nome do Plano"
						text={nomePlano}
						setText={setNomePlano}
						disabled
					/>
					<Input
						width={140}
						title="Preço"
						text={preco}
						setText={setPreco}
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={500}
						title="Quota de Usuários"
						text={quotaUsuarios}
						setText={setQuotaUsuarios}
					/>
				</Body.Row>
			</>
		);
	};

	return (
		<Modal
			title={'Editar Plano - ' + getAplicativo(app)}
			toggleModal={toggle}
		>
			<div
				style={{
					width: '500px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				{app === 'INS' ? insightPlan() : dhmPlan()}
				<br />
				<Button text="Salvar" action={updatePlan} loading={loading} />
			</div>
		</Modal>
	);
};

export default UpdatePlan;
