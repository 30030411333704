import axios from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Buttons/Button/Button';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import * as Types from '../../../../utils/types/Types';
import * as Functions from '../../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import { apiURL } from '../../../../utils/defines/Defines';
import SearchIcon from '../../../../assets/icons/Search.svg';
import { State } from '../../../redux/store';
import userType from '../../../redux/userType/reducer';

type ConfirmDeleteProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	id: string;
	nome: string;
	action: any;
};

export const ConfirmDeleteCompany: React.FC<ConfirmDeleteProps> = ({
	toggle,
	id,
	action,
	nome,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);
	const [loading, toggleLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const tryDelete = () => {
		toggleLoading(true);
		axios
			.delete(
				apiURL +
					'/api/EmpresaCRUD?userTypeRequest=' +
					userType +
					'&idEmpresa=' +
					Functions.ReplaceSpecialCharacters(id),
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Empresa excluida com sucesso',
				});
				toggleLoading(false);
				action();
				toggle(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	return (
		<Modal title="Excluir Empresa">
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Body.Text style={{ marginBottom: '20px', fontWeight: 400 }}>
					Tem certeza que deseja excluir a empresa{' '}
					<strong>{nome}</strong>?
					<br /> Essa ação não poderá ser desfeita.
				</Body.Text>
				<Body.Row style={{ justifyContent: 'center' }}>
					<Button
						text="Excluir"
						color="red"
						width={100}
						action={() => tryDelete()}
						loading={loading}
					/>
					<Button
						text="Cancelar"
						width={100}
						action={() => toggle(false)}
						disabled={loading}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};
