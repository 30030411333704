import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import * as Functions from '../../../utils/functions/Functions';
import CodeInput from '../../components/Inputs/InputCode/Input';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import TectrolLogo from '../../../assets/icons/tectrol-logo.svg';
import DinamoLogo from '../../../assets/icons/dinamo-logo.svg';

const Content = {
	Login: 1,
	TFA: 2,
};

type LoginProps = {
	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
	senha: string;
	setSenha: Dispatch<SetStateAction<string>>;
	toggleContent: Dispatch<SetStateAction<number>>;
};

type TFAProps = {
	email: string;
	senha: string;
	toggleContent: Dispatch<SetStateAction<number>>;
};

const Inicio: React.FC<LoginProps> = ({
	email,
	setEmail,
	senha,
	setSenha,
	toggleContent,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [errorEmail, toggleErrorEmail] = useState(false);
	const [errorPass, toggleErrorPass] = useState(false);
	const [errorLogin, toggleError] = useState(false);
	const [loading, toggleLoading] = useState(false);

	const trySend = () => {
		axios
			.get(`${defines.apiURL}/api/LoginTFA`, { withCredentials: true })
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Código enviado',
				});
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const tryLogin = () => {
		toggleErrorEmail(false);
		toggleError(false);
		toggleErrorPass(false);
		if (!Functions.verifyEmail(email) || senha.length < 12) {
			toggleErrorEmail(!Functions.verifyEmail(email));
			toggleErrorPass(senha.length < 12);
			return;
		}

		toggleLoading(true);
		axios
			.post(
				`${defines.apiURL}/api/Login`,
				{ email, senha, api: process.env.REACT_APP_API_URL! },
				{ withCredentials: true }
			)
			.then((response) => {
				toggleContent(Content.TFA);
				toggleLoading(false);
				trySend();
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch
				);
			});
	};

	return (
		<Body.CardLogin>
			<Body.SubTitle>Login</Body.SubTitle>
			{errorLogin && (
				<Body.TextError>
					Email ou senha inválidos, verifique os dados e tente
					novamente
				</Body.TextError>
			)}
			<Input
				text={email}
				setText={setEmail}
				placeholder="Email"
				error={errorEmail}
			/>
			{errorEmail && (
				<Body.TextError>
					Formato de email inválido ex: email@email.com
				</Body.TextError>
			)}
			<Input
				text={senha}
				setText={setSenha}
				placeholder="Senha"
				error={errorPass}
				password={true}
				action={tryLogin}
			/>
			{errorPass && (
				<Body.TextError>
					Senha inválida mínimo 12 caracteres
				</Body.TextError>
			)}
			<Button text="Entrar" action={tryLogin} loading={loading} />
			<ButtonWithoutBG
				text="Esqueceu sua senha?"
				action={() => navigate('/recuperar?email=' + email)}
			/>
		</Body.CardLogin>
	);
};

const TFA: React.FC<TFAProps> = ({ email, senha, toggleContent }) => {
	const [tfaCode, setCode] = useState('');
	const [time, setTime] = useState(120);
	const [lock, toggleLock] = useState(true);
	const [loading, toggleLoading] = useState(false);
	const [loadingCode, toggleCode] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (time === 0) {
			toggleLock(false);
			return;
		}
		const interval = setInterval(() => {
			setTime((seconds) => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [time]);

	const tryResend = (useSMS: boolean) => {
		if (lock) return;

		toggleCode(true);
		const url = `${defines.apiURL}/api/LoginTFA${useSMS ? '?sms=1' : ''}`;
		axios
			.get(url, { withCredentials: true })
			.then((response) => {
				toggleLock(true);
				toggleCode(false);
				setTime(120);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					text: 'Código enviado',
					title: '',
				});
			})
			.catch((error) => {
				toggleCode(false);
				if (
					error.response.status === 406 &&
					error.response.data.code === 'TFA-3'
				) {
					setTime(120);
					toggleLock(true);
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 3,
						title: '',
						text: error.response.data.message,
					});
					return;
				}
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch
				);
			});
	};

	const tryAuth = () => {
		toggleLoading(true);
		axios
			.post(
				`${defines.apiURL}/api/LoginTFA`,
				{ tfaCode, api: process.env.REACT_APP_API_URL! },
				{ withCredentials: true }
			)
			.then((response) => {
				localStorage.setItem('@logged', 'true');
				toggleLoading(false);
				navigate('/menu');
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch
				);
				toggleLoading(false);
			});
	};

	const getTime = () => {
		if (time === 120) return '02:00';
		if (time > 59)
			return time < 70 ? `01:0${time - 60}` : `01:${time - 60}`;
		return time < 10 ? `00:0${time}` : `00:${time}`;
	};

	return (
		<Body.CardLogin>
			<Body.SubTitle>Autenticação</Body.SubTitle>
			<Body.Text>
				Enviamos um código de segurança para seu celular e email.
				Insira-o para prosseguirmos com seu login.
			</Body.Text>
			<CodeInput setText={setCode} action={tryAuth} />
			<Button text="Continuar" action={tryAuth} loading={loading} />
			{lock ? (
				<Body.Text>Aguarde {getTime()} para enviar novamente</Body.Text>
			) : (
				<>
					<ButtonWithoutBG
						text="Reenviar código por email"
						loading={loadingCode}
						action={() => tryResend(false)}
					/>
					<ButtonWithoutBG
						text="Enviar código por SMS"
						loading={loadingCode}
						action={() => tryResend(true)}
					/>
				</>
			)}
		</Body.CardLogin>
	);
};

const Login = () => {
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');
	const [content, toggleContent] = useState(Content.Login);
	const navigate = useNavigate();

	const getContent = () => {
		return content === Content.Login ? (
			<Inicio
				email={email}
				setEmail={setEmail}
				senha={senha}
				setSenha={setSenha}
				toggleContent={toggleContent}
			/>
		) : (
			<TFA email={email} senha={senha} toggleContent={toggleContent} />
		);
	};

	useEffect(() => {
		if (localStorage.getItem('@logged') === 'true') navigate('/menu');
	}, []);

	return (
		<Body.Container>
			<Body.Row>
				<Body.CompanyLogo
					style={{ height: '23px', marginLeft: '20px' }}
					src={DinamoLogo}
				/>
				<Body.CompanyLogo src={TectrolLogo} />
			</Body.Row>
			<Body.Title>Conecta</Body.Title>
			{getContent()}
		</Body.Container>
	);
};

export default Login;
