import styled from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	width: 100vw;
	height: 100vh;
	padding: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: flex-end;
`;

export const Content = styled.div`
	width: calc(100vw - 420px);
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 10px 10px;
	justify-content: center;
	align-items: center;
`;
export const SubTitle = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
export const Text = styled.a`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 16px;
	color: #000;
	margin-bottom: 5px;
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-size: 24px;
	font-weight: semibold;
`;

export const TitleDisabled = styled.h1`
	font-family: 'Nunito';
	font-size: 24px;
	font-weight: semibold;
	color: #6d7989;
`;

export const Card = styled.div`
	width: 220px;
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 10px 0px;
	text-align: center;
`;

export const Icon = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 12px;
`;

export const IconDisabled = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 12px;
	filter: grayscale(100%);
	opacity: 0.5;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 20px;
`;

export const RedText = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: ${defines.Red};
	margin-bottom: 5px;
	opacity: 0.5;
	font-weight: bold;
`;
