import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import * as Types from '../../../utils/types/Types';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Avatar from '../../../assets/images/avatar.png';
import VisitaIcon from '../../../assets/icons/visita-icon.svg';
import UsuarioIcon from '../../../assets/icons/usuario-icon.svg';
import TecnicoIcon from '../../../assets/icons/tecnico-icon.svg';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';
import { set } from 'date-fns';
import { ConfirmDeleteUnity } from './Modals/ConfirmDeleteUnity';

const EditCompany = () => {
	const { state } = useLocation();
	const { filial, company } = state as { filial: Types.FilialInfo, company: Types.EmpresaInfo };

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<Types.FilialInfo[]>([]);
	const [loading, toggleLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [idFilial, setIdFilial] = useState(filial.idFilial);
	const [nomeEmpresa, setEmpesa] = useState(filial.nomeEmpresa);
	const [nomeFilial, setNomeFilial] = useState(filial.nomeFilial);
	const [endereco, setEndereco] = useState(filial.endereco);
	const [cnpj, setCNPJ] = useState(filial.cnpj);
	const [cnpjFilial, setCnpjFilial] = useState(filial.cnpj || '');

	const updateUnity = () => {
		if (nomeFilial.length === 0 || endereco.length === 0) {
			dispatch({
				type: 'ADD_NOTIFICATION',
				status: 3,
				title: '',
				text: 'Preencha todos os campos',
			});
			return;
		}
		toggleLoading(true);
		axios
			.put(
				defines.apiURL + '/api/FilialCRUD?userTypeRequest=' + userType,
				{ idFilial, nomeFilial, endereco },
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Dados da filial alterados com sucesso',
				});
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
			/>
			<ButtonWrapper
				action={() => {
					navigate('/filiais', { state: company });
				}}
				style={{
					position: 'absolute',
					top: '20px',
					left: '20px',
					minWidth: '50px',
					minHeight: '50px',
					backgroundColor: 'white',
					borderRadius: '50%',
					justifyContent: 'center',
					alignItems: 'center',
					zIndex: 1,
					boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
				}}
			>
				<Body.CardIcon src={ArrowBack} />
			</ButtonWrapper>

			<div style={{ margin: '10px' }} />

			<Body.Row>
				<Input
					text={nomeFilial}
					setText={setNomeFilial}
					placeholder="Nome da Filial"
					title="Nome da Filial"
				/>
			</Body.Row>
			<Body.Row>
				<Input
					text={formatCNPJ(cnpjFilial)}
					setText={setCnpjFilial}
					placeholder="CNPJ da Filial"
					title="CNPJ da Filial"
					disabled
				/>
			</Body.Row>

			<Button text="Visualizar Usuários" color="yellow" action={() => {
				navigate('/usuarios', { state: { filial, company} });
			}} />

			<Button text="Salvar" color="blue" action={() => {updateUnity()}}
				disabled={!(acessLevel !== defines.CargoTectrol['Padrão'])}/>

			<Button
				text="Excluir Filial"
				color="red"
				action={() => {toggleDelete(true)}}
				disabled={!(acessLevel !== defines.CargoTectrol['Padrão'])}
				/>

			{loading && <Loading />}
			{modalDelete ? (
				<ConfirmDeleteUnity
					nome={nomeFilial}
					id={filial.idFilial}
					toggle={toggleDelete}
					action={() => {navigate('/filiais', { state: company })}}				/>
			) : null}
		</Body.Container>
	);
};

export default EditCompany;
