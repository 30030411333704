import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import { State } from '../../../redux/store';

const TipoUsuario = {
	Administrador: 3,
	Supervisor: 2,
	Tecnico: 1,
};

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	email: string;
	visitante?: number;
	cargo: number;
	idVisitante: string;
	idAPI: string;
	validade: string;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	usuario: UsuarioProps;
	filial: FilialProps;
	regenerate: () => void;
	app: string;
};

const EditUser: React.FC<SelectUnitProps> = ({
	toggle,
	usuario,
	filial,
	regenerate,
	app,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [selected, setSelected] = useState(usuario.cargo);
	const [loading, toggleLoading] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const changeUser = () => {
		const apiEndpoint = app === 'INS' ? '/api/InSightCRUD' : '/api/DHMCrud';
		const idKey = app === 'INS' ? 'idInSight' : 'idDHM';
		axios
			.put(
				defines.apiURL +
					apiEndpoint +
					'?userTypeRequest=' +
					userType +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial),
				{
					[idKey]: usuario.idAPI,
					nivelAcesso: selected,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				toggle(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Tipo alterado com sucesso!',
				});
				regenerate();
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	return (
		<Modal
			title={'Mudar permissão - ' + getAplicativo(app)}
			subtitle={usuario.nomeCompleto}
		>
			<>
				<Body.Row style={{ marginTop: '15px', justifyContent: 'center' }}>
					<Body.SelectCard
						active={selected === TipoUsuario.Administrador}
						onClick={() => setSelected(TipoUsuario.Administrador)}
					>
						<Body.IconChangePermission src={AdministradorIcon} />
						<Body.Text>Administrador</Body.Text>
					</Body.SelectCard>
					<Body.SelectCard
						active={selected === TipoUsuario.Supervisor}
						onClick={() => setSelected(TipoUsuario.Supervisor)}
					>
						<Body.IconChangePermission src={EngenheiroIcon} />
						<Body.Text>Supervisor</Body.Text>
					</Body.SelectCard>
					<Body.SelectCard
						active={selected === TipoUsuario.Tecnico}
						onClick={() => setSelected(TipoUsuario.Tecnico)}
					>
						<Body.IconChangePermission src={EngenheiroIcon} />
						<Body.Text>Técnico</Body.Text>
					</Body.SelectCard>
				</Body.Row>
				<Body.Row
					style={{
						gridGap: '0px 10px',
						justifyContent: 'center',
						marginTop: '20px',
					}}
				>
					<Button
						text="Alterar"
						color="green"
						action={() => {
							changeUser();
						}}
						width={120}
						loading={loading}
					/>
					<Button
						text="Cancelar"
						color="red"
						action={() => toggle(false)}
						disabled={loading}
						width={120}
					/>
				</Body.Row>
			</>
		</Modal>
	);
};

export default EditUser;
